import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { PageNavigation } from '../../data/page_navigation';
import { PaymentType } from '../../data/payment.type';
import { ProgramModel } from '../../data/program_model';

@Component({
  selector: 'app-subscription.popup',
  templateUrl: './subscription.popup.component.html',
  styleUrls: ['./subscription.popup.component.scss']
})
export class SubscriptionPopupComponent implements OnInit, OnDestroy {

  @Input() program: ProgramModel;
  @Input() type: string;
  @Input() url: string;
  private popupActive = false;

  @ViewChild("subscriptionModal") content;

  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private subscriptionService: SubscriptionService,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.setCurrentPage(PageNavigation.SUBSCRIPTION_POPUP);
  }

  ngOnDestroy(): void {
    this.popupActive = false;
  }

  open() {
    if (!this.popupActive) {
      this.popupActive = true;
      this.modalService.open(this.content, { windowClass : "subscription-modal" }).result.then((result) => {
        this.popupActive = false;
      }, (reason) => {
        this.popupActive = false;
      });
    }
  }
  
  buyWithCredits() {
    this.subscriptionService.setPaymentType(PaymentType.Credits);
    this.modalService.dismissAll();
    this.popupActive = false;
  }

  buyWithMoney() {
    this.subscriptionService.setPaymentType(PaymentType.Money);
    this.modalService.dismissAll();
    this.popupActive = false;
  }

  explorePrograms() {
    this.router.navigate(['profile/programs']);
    this.modalService.dismissAll();
    this.popupActive = false;
  }
}
