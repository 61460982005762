<div class="d-flex align-items-center justify-content-center">
    <div class="banner-home"></div>
    <div class="position-absolute bg-transparent text-center">
        <p class="h1 bg-transparent home-intro">Вежбај со твоите омилени тренери, <br>било каде, било кога. Со ФитКит Плус.</p>
        <div class="mt-5 bg-transparent">
            <button class="custom-button custom-button-primary border-0" (click)="scrollToElement(programs)">
                Откриј програми
            </button><br>
            <button *ngIf="!isLoggedIn" class="custom-button custom-button-secondary text-white mt-3" (click)="loginClick()">
                Најави се
            </button>
        </div>
    </div>
</div>
<div class="container margin-top-96">
    <p class="h1 font-weight-bold">Што е FitKit Plus?</p>
    <div class="row mt-4">
        <p class="lead col-md-6 col-sm-12">Во соработка со твоите омилени фит инструктори ти креиравме можност да вежбаш од удобноста на твоето омилено катче, во било кое време од денот. FitKit Plus е простор кој ти нуди уникатни фит програми по пилатес, јога и функционални тренинзи.</p>
        <p class="lead col-md-6 col-sm-12">Сите програми се уникатни и дизајнирани од самите инструктори. Со отклучување на програма добиваш уникатна можност и пристап до фит програма која можеш да ја вежбаш кога сакаш, каде сакаш, под твои услови. </p>
    </div>
</div>
<div #programs class="margin-top-96">
    <div>
        <div *ngFor="let program of programsList">
            <div class="container">
                <p class="h1 font-weight-bold">{{program[0]['categoryName']}}</p>
                <p class="lead">Во соработка со твоите омилени фит инструктори ти креиравме.</p>
            </div>
            <ngx-glide [perView]="1.25"
                [showArrows]="false" 
                [showBullets]="false"
                [gap]="30"
                focusAt="center">
                <ng-container *ngFor="let innerProgram of program">
                    <div #containerChild (click)="onProgramClick(innerProgram)">
                        <p class="h1 position-absolute text-white bg-transparent program-name">{{innerProgram['name']}}</p>
                        <p *ngIf="innerProgram['videos'].length == 1" class="h4 position-absolute text-white bg-transparent program-author">со {{innerProgram['coachName']}} - {{innerProgram['videos'].length}}  тренинг - {{innerProgram['levelName']}}</p>
                        <p *ngIf="innerProgram['videos'].length > 1" class="h4 position-absolute text-white bg-transparent program-author">со {{innerProgram['coachName']}} - {{innerProgram['videos'].length}}  тренинзи - {{innerProgram['levelName']}}</p>
                        <div class="program-image-parent">
                            <img [src]="innerProgram['photoUrls'][0]['photo']" class="program-image">
                        </div>
                    </div>
                </ng-container>
            </ngx-glide>
            <div class="margin-top-96"></div>
        </div>
    </div>
</div>