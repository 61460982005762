import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgxGlideModule } from 'ngx-glide';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { DetailsComponent } from './components/details/details.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LandingComponent } from './components/landing/landing.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { CustomButtonService } from './services/custom-button/custom-button.service';
import { PopupComponent } from './components/popup/generic-popup/popup.component';
import { PopupService } from './services/popup/popup.service';
import { VideoService } from './services/popup/video.service';

import { environment } from '../environments/environment';
import { UserProgramsComponent } from './components/user_programs/user-programs.component';
import { ContactComponent } from './components/contact/contact.component';
import { VideoPopupComponent } from './components/popup/video-popup/video-popup.component';
import { SubscriptionPopupComponent } from './components/popup/subscription.popup/subscription.popup.component';
import { SubscriptionService } from './services/subscription/subscription.service';
import { ResetPasswordComponent } from './components/popup/reset-password/reset-password.component';
import { ResetPasswordResultComponent } from './components/popup/reset-password-result/reset-password-result.component';

const firebaseConfig = {
  apiKey: environment.apiKey,
  authDomain: environment.authDomain,
  databaseURL: environment.databaseURL,
  storageBucket: environment.storageBucket,
  messagingSenderId: environment.storageBucket,
  universalUrl: environment.universalUrl
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PopupComponent,
    DetailsComponent,
    ProfileComponent,
    LandingComponent,
    CustomButtonComponent,
    UserProgramsComponent,
    ContactComponent,
    VideoPopupComponent,
    SubscriptionPopupComponent,
    ResetPasswordComponent,
    ResetPasswordResultComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AppRoutingModule,
    NgxGlideModule, 
    NgbModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [CustomButtonService, PopupService, VideoService, SubscriptionService],
  bootstrap: [AppComponent],
  entryComponents: [CustomButtonComponent, PopupComponent, VideoPopupComponent, SubscriptionPopupComponent, ResetPasswordComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
