import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PageNavigation } from '../data/page_navigation';
import { Profile } from '../data/profile/profile';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  public currentProfile: Profile = this.authService.currentProfile;
  public isProfile : boolean;
  public isLoggedIn = this.currentProfile != null;
  public isMyPrograms = false;
  public isMyProfile = false;
  public isContact = false;

  private profileSubscription: Subscription;
  private navigationSubscription: Subscription;

  constructor(public authService: AuthService,
              private navigationService: NavigationService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.profileSubscription = this.authService.profileObserver.subscribe(currentProfile => {
      this.currentProfile = currentProfile;
      this.isLoggedIn = currentProfile != null;
    });

    this.navigationSubscription = this.navigationService.getCurrentPage().subscribe(currentPage => {
      this.isMyProfile = currentPage == PageNavigation.MY_PROFILE;
      this.isMyPrograms = currentPage == PageNavigation.MY_PROGRAMS;
      this.isContact = currentPage == PageNavigation.CONTACT_PROFILE;
      this.isProfile = this.isMyProfile || this.isMyPrograms || this.isContact;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.profileSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  loginClick() {
    document.getElementById("welcomeModal").click();
  }

  myProfileClick() {
    this.navigationService.setCurrentPage(PageNavigation.MY_PROFILE);
    this.router.navigate(['profile']);
  }

  myProgramsClick() {
    this.navigationService.setCurrentPage(PageNavigation.MY_PROGRAMS);
    this.router.navigate(['profile/programs']);
  }

  contactClick() {
    this.router.navigate(['profile/contact']);
  }

  openTerms() {
    window.open('https://fitkit-website.web.app/assets/uslovi_koristenje.pdf');
  }

  openBilling() {
    window.open('https://fitkit-website.web.app/assets/uslovi_kupuvanje.pdf');
  }

  openPrivacy() {
    window.open('https://fitkit-website.web.app/assets/data_processing.pdf');
  }
}
