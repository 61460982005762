import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { ProgramModel } from 'src/app/components/data/program_model';
import { SubscriptionPopupComponent } from 'src/app/components/popup/subscription.popup/subscription.popup.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPopupService {

  constructor(private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver) { }

    showAsElement(program: ProgramModel, type: string, url: string) {
      // Create element
      const popupEl: NgElement & WithProperties<SubscriptionPopupComponent> = document.createElement('subscription-popup') as any;

      // Listen to the close event
      popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));

      // Set the type and video
      popupEl.program = program;
      popupEl.type = type;
      popupEl.url = url;

      // Add to the DOM
      document.body.appendChild(popupEl);
    }
}
