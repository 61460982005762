import { Component, OnInit, ElementRef, ViewChildren, OnDestroy } from '@angular/core';
import { fromEvent, Subscription, timer } from 'rxjs';
import { Program } from '../data/program';
import { VodService } from '../../services/vod/vod.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { QueryList } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PageNavigation } from '../data/page_navigation';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private vodService: VodService,
              private router: Router,
              private authService: AuthService,
              private navigationService: NavigationService) {}

  @ViewChildren('containerChild') mapElem: QueryList<ElementRef>;

  public programsList = [];
  public isLoggedIn = this.authService.currentProfile != null;
  
  private isClick = false;
  private programSubscription: Subscription;
  private profileSubscription: Subscription;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.navigationService.setCurrentPage(PageNavigation.HOME);
    this.programSubscription = this.getAllProgramModels();
    this.profileSubscription = this.authService.profileObserver.subscribe(currentProfile => {
      this.isLoggedIn = currentProfile != null;
    });
  }

  ngOnDestroy(): void {
    this.programSubscription.unsubscribe();
    this.profileSubscription.unsubscribe();
  }

  private getAllProgramModels(): Subscription {
    return this.vodService.getProgramModels().subscribe(programList => {
      this.programsList = programList;
      setTimeout(() => {
        this.createClickListener();
        this.programSubscription.unsubscribe();
      }, 200);
    });
  }

  createClickListener() {
    this.mapElem.forEach(mapElem => {
      const mousedown$ = fromEvent(mapElem.nativeElement, 'mousedown');
      const mouseup$ = fromEvent(mapElem.nativeElement, 'mouseup');
  
      mousedown$.subscribe(() => {
        this.isClick = false;
        const clickTimer$ = timer(80);
        mouseup$.pipe(takeUntil(clickTimer$)).subscribe(() => {
          this.isClick = true;
          console.log("click");
        });
      });
    });
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView();
  }

  onProgramClick(program: Program) {
    if (this.isClick) {
      this.router.navigate(['details/', program.id]);
      this.isClick = false;
    }
  }

  loginClick() {
    document.getElementById("welcomeModal").click();
  }
}
