import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Video } from '../../data/video';
import { AuthService } from '../../../services/auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { City } from 'src/app/components/data/city';
import { CityService } from 'src/app/services/city/city.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ProfileUpdateError } from '../../data/profile/profile_update_error';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PageNavigation } from '../../data/page_navigation';

@Component({
  selector: 'app-login',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnChanges {

  public rating = 0;
  public loginInfo: FormGroup;
  public registerInfo: FormGroup;
  public cityList: Observable<City[]>;
  public defaultProfileIcon = "../../assets/images/profile_icon.png";
  public profileIcon = new BehaviorSubject<string>(this.defaultProfileIcon);
  public registrationEmailIsValid = true;
  public profileIconUploadFail = false;
  public firebaseFail = false;
  public invalidFitkitLogin = false;
  public cacheLogin : boolean = false;

  @Input() popupType: string;
  @Input() video: Video;
  @Input() header: string;

  private closeResult: string;
  private description: string;
  private lastRegisterEmail: string;
  private emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  private popupClosed : boolean = false;

  constructor(private modalService: NgbModal, 
              private authService: AuthService, 
              private cityService: CityService,
              private navigationService: NavigationService,
              private fb: FormBuilder,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.navigationService.setCurrentPage(PageNavigation.GENERIC_POPUP);
    this.createForms();
    this.cityList = this.cityService.getCityList();
  }

  ngOnChanges(): void {
    this.setValuesByType();
  }

  private createForms() {
    this.profileIcon.next(this.defaultProfileIcon);
    
    this.loginInfo = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });

    this.registerInfo = this.fb.group({
      name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zАБВГДЃЕЖЗSИЈКЛЉМНЊОПРСТЌУФХЦЧЏШабвгдѓежзѕијклљмнњопрстќуфхцчџш]+$')]),
      surname: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zАБВГДЃЕЖЗSИЈКЛЉМНЊОПРСТЌУФХЦЧЏШабвгдѓежзѕијклљмнњопрстќуфхцчџш]+$')]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      city: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zАБВГДЃЕЖЗSИЈКЛЉМНЊОПРСТЌУФХЦЧЏШабвгдѓежзѕијклљмнњопрстќуфхцчџш]+$')]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      pictureUrl: new FormControl('', Validators.required)
    }, {
      validators: [this.MatchPassword]
    });

    this.invalidFitkitLogin = false;
    this.firebaseFail = false;
    this.profileIconUploadFail = false;
    this.registrationEmailIsValid = true;
    this.lastRegisterEmail = null;
  }

  private setValuesByType() {
    switch (this.popupType) {
      case "login":
        this.header = "Login bruh";
        break;
      case 'ratings':
        this.header = "Што мислиш?";
        this.description = "<p class='lead'>Постојано работиме на подобрување и усовршување на продуктот. Твојот став е оној кој ќе направи нејголем импакт во промените кои следат. Помогни ни со рејтинг или <a class='lead popup-link' href='mailto:info@fitkit.app'>пиши ни<a/> <span class='lead'>(на <b class='lead font-weight-bold'>info@fitkit.app</b>) што мислиш за програмата.</span></p>";
        break;
      default: 
        break;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  private closePopup() {
    if (!this.popupClosed) {
      document.getElementById('popup-close').click();
      this.createForms();
      this.popupClosed = true;
    }
  }

  private MatchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('confirmPassword').value;

    if (password != confirmPassword) {
      return { ConfirmPassword: true };
    } else {
        return null
    }
  }

  open(content, type) {
    this.popupClosed = false;
    this.popupType = type;
    this.setValuesByType();  
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.rating = 0;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.rating = 0;
    });
  }

  sendRating() {
    this.closePopup();
  }

  loginWithFacebook() {
    this.spinner.show();
    this.authService.logInWithFacebook().subscribe(_ => {
      this.spinner.hide();
      this.closePopup();
    });
  }

  openBasicLogin() {
    this.closePopup();
    document.getElementById("loginModal").click();
  }

  createProfile() {
    this.closePopup();
    document.getElementById("registerModal").click();
  }

  basicLogin() {
    this.loginWithParams(this.loginInfo.value.email, this.loginInfo.value.password);
  }

  registerUser() {
    this.spinner.show();

    setTimeout(() => {
      this.authService.registerUser({
        name: this.registerInfo.value.name,
        firstName: this.registerInfo.value.name,
        lastName: this.registerInfo.value.surname,
        city: this.registerInfo.value.city,
        email: this.registerInfo.value.email,
        password: this.registerInfo.value.password,
        picture: this.registerInfo.value.pictureUrl
      }).subscribe(result => {
        if (result.success) {
          this.loginWithParams(result.profile.email, this.registerInfo.value.password);
          this.closePopup();
        } else {
          switch (result.error) {
            case ProfileUpdateError.EMAIL_TAKEN:
              this.registrationEmailIsValid = false;
              this.lastRegisterEmail = this.registerInfo.value.email;
              break;
            case ProfileUpdateError.PHOTO_UPLOAD_FAIL:
              this.profileIconUploadFail = true;
              break;
            case ProfileUpdateError.FIREBASE_ERROR:
              this.firebaseFail = true;
              break;
            default: 
              break;
          }
          this.spinner.hide();
        }
      });
    }, 500);
  }

  addPhoto() {
    document.getElementById("popup-file").click();
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files as File[];
      if (!file.type.startsWith('image')) {
        (<FormControl>this.registerInfo.get('pictureUrl')).setErrors({
          required: true
        });
      } else {
        (<FormControl>this.registerInfo.get('pictureUrl')).setValue(file);
        let reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      }
    }
  }

  private handleReaderLoaded(e) {
    var reader = e.target;
    this.profileIcon.next(reader.result);
  }

  onEmailCharChange(registerEmail) {
    if (this.lastRegisterEmail != null) {
      this.registrationEmailIsValid = this.lastRegisterEmail != registerEmail;
    } else {
      this.registrationEmailIsValid = true;
    }
  }

  private loginWithParams(email: string, password: string) {
    this.spinner.show();
    this.authService.logInWithFitKitAccount(email, password).subscribe(loginInfo => {
      if (loginInfo.success) {
        this.closePopup();
      } else {
        this.invalidFitkitLogin = true;
      }
      this.spinner.hide();
    });
  }

  onFitkitLoginChange() {
    if (this.invalidFitkitLogin) {
      this.invalidFitkitLogin = false;
    }
  }

  onCacheChange(event: any) {
    this.cacheLogin = event.target.checked;
    console.log("Cache value changed: " + this.cacheLogin);
    this.authService.cacheLogin = this.cacheLogin;
  }

  help() {
    document.getElementById("resetPassModal").click();
  }
}
