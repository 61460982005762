<button id="resetPassResultModal" class="d-none" (click)="open(resetpassresultmodal)"></button>

<ng-template #resetpassresultmodal let-modal>
    <div class="popup-close-parent">
        <img id="popup-close" class="popup-close float-right" src="../../../assets/images/popup_close.png" (click)="modal.close()">
    </div>
    <div class="popup-body">
        <div *ngIf="isValid">
            <p>Испратен е имејл за ресетирање на лозинка!</p>
        </div>
        <div *ngIf="!isValid">
            <p>Грешка: имејлот е невалиден.</p>
        </div>
        <div class="text-center margin-popup-medium">
            <button class="custom-button custom-button-primary border-0 shadow-nonе" (click)="modal.close()">
                Продолжи
            </button>
        </div>
    </div>
</ng-template>