import { Injectable } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { SafeResourceUrl } from '@angular/platform-browser';
import { VideoPopupComponent } from 'src/app/components/popup/video-popup/video-popup.component';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor() { }

  showAsElement(url: SafeResourceUrl, name: string) {
    // Create element
    const videoPopupElement: NgElement & WithProperties<VideoPopupComponent> 
                              = document.createElement('video-popup') as any;

    // Listen to the close event
    videoPopupElement.addEventListener('closed', 
      () => document.body.removeChild(videoPopupElement));

    // Set the type and video
    videoPopupElement.videoUrl = url;
    videoPopupElement.videoName = name;

    // Add to the DOM
    document.body.appendChild(videoPopupElement);
  }
}
