<button id="subscriptionModal" class="d-none" (click)="open()"></button>

<ng-template #subscriptionModal let-modal class="modal">
    <div class="subscription-modal">
        <div class="popup-close-parent">
            <img id="popup-close" class="popup-close float-right" src="../../../assets/images/popup_close.png" (click)="modal.close('Save click')">
        </div>
        <div *ngIf="type == 'paymentWithFunds'">
            <div class="popup-body">
                <img src="../../../assets/images/logo.png" class="popup-logo">
                <p class="h1 modal-title margin-popup font-weight-bold" id="modal-basic-title">Добредојде, {{ authService.getFirstName() }}</p>
                <p class="lead margin-popup popup-description">Твојот FitKit профил веќе има {{ program.credits }} кредити, кои се доволни за да ја отклучиш програмата. Дали сакаш да ги искористиш тие {{ program.credits }} кредити или програмата да ја отклучиш со платежна картичка за {{ program.price }} денари?</p>
                <div class="text-center">
                    <button class="custom-button custom-button-primary border-0 margin-popup subscription-button" (click)="buyWithCredits()">
                        Отклучи со {{ program.credits }} кредити
                    </button><br>
                    <button class="custom-button custom-button-secondary-inverted margin-popup-small subscription-button" (click)="buyWithMoney()">
                        Отклучи за {{ program.price }} ден.
                    </button>
                    <p class="lead price">цената е со вклучен ддв од 18%</p>
                </div>
            </div>
        </div>
        <div *ngIf="type == 'paymentPermission'">
            <div class="popup-body">
                <img src="../../../assets/images/logo.png" class="popup-logo">
                <p class="h1 modal-title margin-popup font-weight-bold" id="modal-basic-title">Добредојде, {{ authService.getFirstName() }}</p>
                <p class="lead margin-popup popup-description">Продолжи со отклучување на програмата за {{ program.price }} денари?</p>
                <div class="text-center">
                    <button class="custom-button custom-button-primary border-0 margin-popup" (click)="buyWithMoney()">
                        Отклучи за {{ program.price }} ден.
                    </button>
                    <p class="lead price">цената е со вклучен ддв од 18%</p>
                </div>
            </div>
        </div>
        <div *ngIf="type == 'unlockedProgram'">
            <div class="popup-body">
                <img src="../../../assets/images/logo.png" class="popup-logo">
                <p class="h1 modal-title margin-popup font-weight-bold" id="modal-basic-title">Честитки, {{ authService.getFirstName() }}</p>
                <p class="lead margin-popup popup-description">Успешно отклучена програма:</p>
                <p class="h4 margin-popup-small font-weight-bold">{{ program.name }}</p>
                <p class="lead margin-popup-small popup-description">Можеш да ја најдеш на FitKit App или на веб страната на FitKit Plus. За оптимално искуство ти пропорачуваме да ја <a href="https://support.vhx.tv/article/356-watching-chromecast#:~:text=On%20the%20video%20page%2C%20open,be%20playing%20on%20your%20TV." target="_blank" class="link">video cast</a> на твојот smart телевизор. Пријатно потење!</p>
                <div class="text-center">
                    <button class="custom-button custom-button-primary border-0 margin-popup" (click)="explorePrograms()">
                        Мои програми
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>