import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PageNavigation } from 'src/app/components/data/page_navigation';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private currentPageObserver: Subject<PageNavigation> = new Subject<PageNavigation>();
  private currentPage = PageNavigation.HOME;

  constructor() { }

  setCurrentPage(page: PageNavigation) {
    this.currentPage = page;
    this.currentPageObserver.next(page);
  }

  getCurrentPage(): Subject<PageNavigation> {
    return this.currentPageObserver;
  }

  getIsProfile(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      observer.next(this.currentPage == PageNavigation.MY_PROFILE
        || this.currentPage == PageNavigation.MY_PROGRAMS
        || this.currentPage == PageNavigation.CONTACT);
    });
  }
}
