import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { ProfileUpdate } from 'src/app/components/data/profile/profile_update';
import { ProfileUpdateResult } from 'src/app/components/data/profile/profile_update_result';
import { AuthService } from '../auth/auth.service';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';
import { Profile } from 'src/app/components/data/profile/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public isUpdating = new BehaviorSubject<boolean>(false);
  public profileUpdateResult = new BehaviorSubject<ProfileUpdateResult>(null);

  private updateInProgress = false;
  //TODO: this is a temporary hack to not get error when updating email
  private lastExistingUserUpdate : string;

  constructor(private db: AngularFireDatabase,
              private authService: AuthService) {
  }

  startUpdate(profileUpdate: ProfileUpdate): Observable<[string, string, string, string]> {
    this.setIsUpdating(true);
    return new Observable<[string, string, string, string]>(observer => {
      this.lastExistingUserUpdate = null;
      let updateImageAsync = this.updateImage(profileUpdate.photo);
      let updateNameAsync = this.updateName(profileUpdate.name.name, profileUpdate.name.surname);
      let updateEmailAsync = this.updateEmail(profileUpdate.email.password,
        profileUpdate.email.email);
      let updatePasswordAsync = this.updatePassword(profileUpdate.password.password,
        profileUpdate.password.currentPassword);
      combineLatest([updateImageAsync,
        updateNameAsync,
        updateEmailAsync,
        updatePasswordAsync]).subscribe(update => {
          let imageUrl = update[0];
          let nameResult = update[1];
          let emailResult = update[2];
          let passwordResult = update[3];
          if (imageUrl != null && imageUrl != "success") {
            this.authService.currentProfile.photoUrl = imageUrl;
          }
          if (profileUpdate.name.name != ""
            && profileUpdate.name.surname != ""
            && nameResult == "success") {
            this.authService.currentProfile.name = profileUpdate.name.name + " " + profileUpdate.name.surname;
            this.authService.currentProfile.firstName = profileUpdate.name.name;
            this.authService.currentProfile.lastName = profileUpdate.name.surname;
            this.authService.updateCurrentProfile(this.authService.currentProfile);
          }
          if (emailResult != null && profileUpdate.email.email != "" && emailResult == "success") {
            this.authService.currentProfile.email = profileUpdate.email.email;
            this.authService.updateCurrentProfile(this.authService.currentProfile);
          }
          if (passwordResult != null && passwordResult == "success") {
            // no logic needed at the moment, only update on Firebase
          }
          observer.next(update);
          this.setIsUpdating(false);
        });
    });
  }

  private updatePassword(password?: string, oldPassword?: string): Observable<string> {
    return new Observable<string>(observer => {
      if (password == null || password == "" || oldPassword == null || oldPassword == "") {
        observer.next("success");
      } else {
        let accessToken = this.authService.currentProfile.facebookToken;
        this.updateFirebasePassword(password, oldPassword, accessToken).subscribe(result => {
          observer.next(result);
        });
      }
    });
  }

  private updateEmail(password?: string, email?: string): Observable<string> {
    return new Observable<string>(observer => {
      if (password == null || password == "" || email == null || email == "") {
        observer.next("success");
      } else {
        var existingUserAsync = this.getExistingUserWithEmail(email).subscribe(profile => {
          if (profile == null) {
            let accessToken = this.authService.currentProfile.facebookToken;
            this.updateFirebaseEmail(password, email, accessToken)
              .subscribe(result => {
                if (result == "success") {
                  this.updateFitkitEmail(email).subscribe(result => {
                    //Temporary hack
                    if (this.lastExistingUserUpdate == null) {
                      this.lastExistingUserUpdate = result;
                      observer.next(result);
                    }
                  });
                } else {
                  observer.next(result);
                }
                existingUserAsync.unsubscribe();
              });
          } else {
            observer.next("EMAIL_TAKEN");
          }
        });
      }
    });
  }

  private updateName(name?: string, surname?: string): Observable<string> {
    return new Observable<string>(observer => {
      if (name == null || name == "" || surname == null || surname == "") {
        observer.next("success");
      } else {
        this.updateFirebaseName(name, surname).subscribe(returnedName => {
          if (returnedName == null) {
            observer.next(null);
          } else {
            this.updateFitkitName(name, surname).then(_ => {
              observer.next("success");
            })
          }
        });
      }
    });
  }

  private updateImage(photo?: File): Observable<string> {
    return new Observable<string>(observer => {
      this.authService.uploadProfileImage(this.authService.currentProfile.id, photo)
        .subscribe(downloadURL => {
          if (downloadURL == null || downloadURL == "") {
            observer.next("success");
          } else {
            this.updateFirebaseImage(downloadURL).subscribe(photoUrl => {
              if (photoUrl == null) {
                observer.next(null);
              } else {
                this.updateFitkitProfilePhoto(downloadURL).then(_ => {
                  observer.next(downloadURL);
                });
              }
            });
          }
        });
    });
  }

  private updateFirebaseName(name: string, surname: string): Observable<string> {
    return new Observable<string>(observer => {
      var user = firebase.auth().currentUser;
      let displayName = name + " " + surname;
      user.updateProfile({
        displayName: displayName
      }).then(() => {
        observer.next(displayName);
      }).catch(error => {
        observer.next(null);
      });
    });
  }

  private updateFirebaseImage(photoUrl: string): Observable<string> {
    return new Observable<string>(observer => {
      var user = firebase.auth().currentUser;
      user.updateProfile({
        photoURL: photoUrl
      }).then(() => {
        observer.next(photoUrl);
      }).catch(error => {
        observer.next(null);
      });
    });
  }

  private updateFirebaseEmail(password: string, email: string,
    facebookToken?: string): Observable<string> {
    return new Observable<string>(observer => {
      var user = firebase.auth().currentUser;
      const credentials = facebookToken != undefined
        ? firebase.auth.FacebookAuthProvider.credential(facebookToken)
        : firebase.auth.EmailAuthProvider.credential(this.authService.currentProfile.email, password);
      user.reauthenticateWithCredential(credentials).then(_ => {
        user.updateEmail(email).then(_ => {
          observer.next("success");
        }).catch(error => {
          observer.next("EMAIL_UPDATE_FAILED");
        });
      }).catch(error => {
        observer.next("EMAIL_CREDENTIALS_FAILED");
      });
    });
  }

  private updateFirebasePassword(password: string,
    oldPassword: string, facebookToken?: string): Observable<string> {
    return new Observable<string>(observer => {
      var user = firebase.auth().currentUser;
      const credentials = facebookToken != undefined
        ? firebase.auth.FacebookAuthProvider.credential(facebookToken)
        : firebase.auth.EmailAuthProvider.credential(this.authService.currentProfile.email, oldPassword);
      user.reauthenticateWithCredential(credentials).then(_ => {
        user.updatePassword(password).then(_ => {
          observer.next("success");
        }).catch(error => {
          observer.next("PASSWORD_UPDATE_FAILED");
        });
      }).catch(error => {
        observer.next("PASSWORD_CREDENTIALS_FAILED");
      });
    });
  }

  private setIsUpdating(isUpdating: boolean) {
    this.updateInProgress = isUpdating;
    this.isUpdating.next(this.updateInProgress);
  }

  private updateFitkitProfilePhoto(photoUrl: string): Promise<void> {
    let writes = {};
    writes['/profile/' + this.authService.currentProfile.id + '/photoUrl'] = photoUrl;
    return this.db.object('/').update(writes);
  }

  private updateFitkitEmail(email: string): Observable<any> {
    return new Observable<any>(observer => {
      let writes = {};
      writes['/profile/' + this.authService.currentProfile.id + '/email'] = email;
      this.db.object('/').update(writes).then(_ => {
        observer.next("success");
      }).catch(error => {
        observer.next(null);
      });
    });
  }

  private updateFitkitName(name: string, surname: string): Promise<void> {
    let writes = {};
    writes['/profile/' + this.authService.currentProfile.id + '/firstName'] = name;
    writes['/profile/' + this.authService.currentProfile.id + '/lastName'] = surname;
    writes['/profile/' + this.authService.currentProfile.id + '/name'] = name + " " + surname;
    return this.db.object('/').update(writes);
  }

  private getExistingUserWithEmail(email: string): Observable<Profile> {
    return new Observable<Profile>(observer => {
      let existingUserAsync = this.db.list('profile/').valueChanges()
      .subscribe((profiles: Profile[]) => {
        var profileToReturn: Profile = null;
        profiles.forEach(profile => {
          if (profile.email == email) {
            profileToReturn = profile;
            observer.next(profileToReturn);
          }
        });
        if (profileToReturn == null) {
          observer.next(null);
        }
        existingUserAsync.unsubscribe();
      });
    });
  }
}