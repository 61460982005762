import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PageNavigation } from '../data/page_navigation';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  private isLoggedIn = this.authService.currentProfile != null;
  public contact: FormGroup;

  constructor(private navigationService: NavigationService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.contact =  new FormGroup({
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });

    var isInProfile = false;
    if (this.router.url == "/profile/contact") {
      this.navigationService.setCurrentPage(PageNavigation.CONTACT_PROFILE);
      isInProfile = true;
    } else {
      this.navigationService.setCurrentPage(PageNavigation.CONTACT);
    }

    if (isInProfile && !this.isLoggedIn) {
      this.router.navigate(['/']);
    }
    document.getElementById("contactUs").click();
  }

  sendOpinion() {
    alert("Ти благодариме за мислењето!")
    this.router.navigate(['profile/programs']);
    //TODO: need to know current email provider for FitKit and create service for sending mail
  }
}
