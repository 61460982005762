import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { DetailsComponent } from './components/details/details.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PopupComponent } from './components/popup/generic-popup/popup.component';
import { LandingComponent } from './components/landing/landing.component';
import { UserProgramsComponent } from './components/user_programs/user-programs.component';
import { ContactComponent } from './components/contact/contact.component';
import { AppVideoComponent } from './components/app-video/app.video.component';

const routes: Routes = [
  { path: '', component: LandingComponent, children: [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'details/:id', component: DetailsComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'login', component: PopupComponent },
    { path: 'profile/programs', component: UserProgramsComponent },
    { path: 'profile/contact', component: ContactComponent }
  ]},
  { path: 'app/video/:videoId/:secret', component: AppVideoComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    [ RouterModule.forRoot(routes) ]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
