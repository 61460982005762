import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { CustomButtonComponent } from 'src/app/components/custom-button/custom-button.component';

@Injectable({
  providedIn: 'root'
})
export class CustomButtonService {

  constructor(private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver) { }

  // This uses the new custom-element method to add the popup to the DOM.
  showAsElement(text: string) {
    // Create element
    const customButtonElement: NgElement & WithProperties<CustomButtonComponent> = document.createElement('custom-button') as any;

    // Listen to the close event
    customButtonElement.addEventListener('closed', () => document.body.removeChild(customButtonElement));

    // Set the message
    customButtonElement.buttonText = text;

    // Add to the DOM
    document.body.appendChild(customButtonElement);
  }
}
