export enum PageNavigation {
    HOME,
    DETAILS,
    MY_PROGRAMS,
    MY_PROFILE,
    CONTACT_PROFILE,
    CONTACT,
    GENERIC_POPUP,
    VIDEO_POPUP,
    SUBSCRIPTION_POPUP
}