import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetPassInfo: FormGroup;
  public isResetPassValid = new BehaviorSubject<Boolean>(false);

  constructor(public modalService: NgbModal,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.resetPassInfo = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
    });
  }

  open(content) {
    this.modalService.open(content);
  }

  resetPassword() {
    this.authService.resetPassword(this.resetPassInfo.value.email).then(result => {
      this.isResetPassValid.next(true);
      document.getElementById("resetPassResultModal").click();
    }).catch(error => {
      this.isResetPassValid.next(false);
      document.getElementById("resetPassResultModal").click();
    });
  }
}
