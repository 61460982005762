import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { Video } from 'src/app/components/data/video';
import { PopupComponent } from 'src/app/components/popup/generic-popup/popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(private injector: Injector,
              private applicationRef: ApplicationRef,
              private componentFactoryResolver: ComponentFactoryResolver) { }

  showAsElement(popupType: string, header: string, video: Video) {
    // Create element
    const popupEl: NgElement & WithProperties<PopupComponent> = document.createElement('popup-element') as any;

    // Listen to the close event
    popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));

    // Set the type and video
    popupEl.popupType = popupType;
    popupEl.video = video;
    popupEl.header = header;

    // Add to the DOM
    document.body.appendChild(popupEl);
  }
}
