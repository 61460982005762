import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, map, switchMap } from 'rxjs/operators';
import { VodProductModel } from 'src/app/components/data/vod.product.model';
import { BookVodModel } from 'src/app/components/data/book.vod.model';
// import { CpayRequestModel } from 'src/app/components/data/cpayrequest.model';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private afAuth: AngularFireAuth,
              public http: HttpClient,
              private db: AngularFireDatabase) { }

  public getVodProducts(): Observable<VodProductModel[]> {
    return this.db.list('/vod/product').valueChanges() as Observable<VodProductModel[]>;
  }

  public bookProgram(productModel: BookVodModel): Observable<VodProductModel> {
    const url = `${environment.fitkitApi.url}/booking/bookVod`;
    return this.getHttpHeaders().pipe(
      switchMap((headers) => {
        return this.http.post<VodProductModel>(
          url,
          productModel,
          {
            headers,
          },
        );
      }),
    );
  }

  private getHttpHeaders(): Observable<HttpHeaders> {
    return from(this.afAuth.idToken).pipe(
      take(1),
      map((token) => {
        return new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        });
      }),
    );
  }
}
