<div class="wrapper">
    <div class="navbar navbar-default text-center top-bar">
        <div class="d-flex w-100 align-items-center justify-content-between header">
            <div>
                <a routerLink="" class="float-left"><img src="../../assets/images/fitkit_plus.png" class="landing-logo"></a>
            </div>
            <div *ngIf="isLoggedIn" class="router-link" routerLink="profile/programs">
                <a class="h3 router-link login-text">{{authService.getFirstName()}}
                <img src="{{currentProfile.photoUrl}}" class="profile-icon">
                </a>
            </div>
            <div *ngIf="!isLoggedIn" (click)="loginClick()">
                <a class="h3 router-link login-text">Најави се
                <img src="../../assets/images/profile_icon.png" class="profile-icon">
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="isProfile" class="profile-top-bar d-flex">
        <button *ngIf="isMyPrograms" class="lead profile-btn-active" 
                (click)="myProgramsClick()">Мои програми</button>
        <button *ngIf="!isMyPrograms" class="lead profile-btn" 
                (click)="myProgramsClick()">Мои програми</button>
        <button *ngIf="isMyProfile" class="lead profile-btn-active"
                (click)="myProfileClick()">Профил</button>
        <button *ngIf="!isMyProfile" class="lead profile-btn" 
                (click)="myProfileClick()">Профил</button>
        <button *ngIf="isContact" class="lead profile-btn-active" 
                (click)="contactClick()">Пиши ни</button>
        <button *ngIf="!isContact" class="lead profile-btn" (click)="contactClick()">
            Пиши ни
            <a id="contactUs" class="lead link d-none" href="mailto:info@fitkit.app">Пиши ни</a>
        </button>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="push"></div>
</div>
<footer class="footer navbar navbar-default">
    <div class="container d-flex w-100 justify-content-between align-items-center footer-item">
        <a routerLink="/home"><img src="../../assets/images/logo_white.png" class="footer-logo"></a>
        <div class="footer-item">
            <span class="h5 bg-transparent text-white router-link" (click)="openTerms()">TERMS</span><br>
            <span class="h5 bg-transparent text-white router-link" (click)="openBilling()">BILLING</span><br>
            <span class="h5 bg-transparent text-white router-link" (click)="openPrivacy()">PRIVACY</span>
        </div>
        <div class="footer-item">
            <span class="h5">Доколку си инструктор и сакаш</span><br>
            <span class="h5">да ни се придружиш со твоја</span><br>
            <span class="h5">фит програма? <a routerLink="profile/contact" class="h5 footer-link">Пиши ни</a></span>
        </div>
        <div>
            <!-- Empty div -->
        </div>
        <div class="bg-transparent">
            <a href="http://instagram.com/fitkitmk"><img src="../../assets/images/instagram.png" class="footer-social"></a>
            <a href="https://www.facebook.com/fitkitmk"><img src="../../assets/images/facebook.png" class="footer-social"></a>
            <a href="https://twitter.com/fitkitmk"><img src="../../assets/images/twitter.png" class="footer-social"></a>
            <a href="https://fitkit.medium.com/"><img src="../../assets/images/medium.png" class="footer-social"></a>
            <a href="https://www.linkedin.com/company/fitkitmk"><img src="../../assets/images/linkedin.png" class="footer-social"></a>
        </div>
    </div>
</footer>
<div class="about-us w-100">
    <div class="container d-flex justify-content-between align-items-center">
        <a href='https://www.ecommerce4all.mk/badge' class="invisible"><img src='https://www.ecommerce4all.mk/verifiedbadge/small.png' class="ecommercemk"/></a>
        <div class="text-center">
            <span class="text-muted fs-6 about-us-credits">Оваа страница припаѓа на Фиткит Интернешнел ДОО Скопје, ул. Вангел Тодоровски 5/1</span><br>
            <span class="text-muted fs-6 about-us-credits">ЕДБ: 4032021548026</span>
        </div>
        <a href='https://www.ecommerce4all.mk/badge'><img src='https://www.ecommerce4all.mk/verifiedbadge/small.png' class="ecommercemk"/></a>
    </div>
    <div class="mt-5"></div>
</div>
<popup-element></popup-element>