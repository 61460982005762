import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-video',
  templateUrl: './app.video.component.html',
  styleUrls: ['./app.video.component.scss']
})
export class AppVideoComponent implements OnInit {

  public isLoaded: Boolean = false;
  private videoId = "";

  constructor(private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private authService: AuthService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinner.show();
    }, 200);
    const secret = this.route.snapshot.paramMap.get('secret');
    const secretArray = secret.split("FKKF");
    if (secretArray.length < 3) {
      return;
    }
    const userId = secretArray[0];
    const email = secretArray[1];
    const timestamp = parseInt(secretArray[2]);
    this.authService.getProfileWithEmail(email).subscribe(profile => {
      if (profile && profile.id == userId && this.isTimestampValid(timestamp)) {
        this.videoId = this.route.snapshot.paramMap.get('videoId');
        this.isLoaded = true;
      }
      this.spinner.hide();
    });
  }

  private isTimestampValid(timestamp: number): Boolean {
    const now = new Date();
    const dateFromTimestamp = new Date(timestamp);
    const validDifference = 1 * 60 * 1000;
    return now.getTime() - dateFromTimestamp.getTime() < validDifference;
  }

  convertToSafeUrl(): SafeResourceUrl {
    if (!this.isLoaded) {
      return null;
    }
    const vimeoUrl = "https://player.vimeo.com/video/";
    const vimeoOptions = "?autoplay=1&loop=1&muted=1";
    const url = vimeoUrl + this.videoId + vimeoOptions;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
