import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { VodService } from 'src/app/services/vod/vod.service';
import { PageNavigation } from '../data/page_navigation';
import { ProgramModel } from '../data/program_model';
import { Video } from '../data/video';
import { SubscriptionModalType } from '../data/subscription.modal.type';
import { BookVodResponseModel } from '../data/book.vod.response.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BookVodModel } from '../data/book.vod.model';
import { PaymentType } from '../data/payment.type';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {

  public program : ProgramModel;
  public isLoaded = false;
  public isLocked = true;
  public clickedVideoUrl = new BehaviorSubject<SafeResourceUrl>("");
  public clickedVideoName = new BehaviorSubject<string>("");
  public clickedVideo = new BehaviorSubject<Video>(null);
  public subscriptionModalType = new BehaviorSubject<string>("payment");
  public subscriptionUrl = new BehaviorSubject<string>("");
  public programObservable = new BehaviorSubject<ProgramModel>(null);
  public subscriptionPopupActive = true;

  private programSubscription: Subscription;
  private unlockProgramSubscription: Subscription;
  private paymentSubscription: Subscription;
  private paymentTypeSubscription: Subscription;
  private isDescriptionClick = false;

  constructor(private vodService: VodService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private subscriptionService: SubscriptionService,
              private authService: AuthService,
              private navigationService: NavigationService,
              private sanitizer: DomSanitizer,
              private paymentService: PaymentService,
              private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.navigationService.setCurrentPage(PageNavigation.DETAILS);
    this.loadProgram();
    this.observePaymentType();
  }
  
  ngOnDestroy(): void {
    this.programSubscription.unsubscribe();
    if (this.unlockProgramSubscription != null) {
      this.unlockProgramSubscription.unsubscribe();
    }
    if (this.paymentSubscription != null) {
      this.paymentSubscription.unsubscribe();
    }
    if (this.paymentTypeSubscription != null) {
      this.paymentTypeSubscription.unsubscribe();
    }
    this.subscriptionPopupActive = false;
  }

  onVideoClick(index: number) {
    if (this.isDescriptionClick) {
      this.isDescriptionClick = false;
      return;
    }
    
    if (this.isLocked && this.program.videos[index].id != "teaser") {
      this.unlockProgram();
    } else {
      this.openVideo(index);
    }
  }

  unlockProgram(buyWithCredits = false) {
    if (!!this.authService.currentProfile) {
      this.spinner.show();
      this.unlockProgramSubscription = this.subscriptionService.getProductWithId(this.program.productId).subscribe(product => {
        const bookVodModel: BookVodModel = {
          userId: this.authService.currentProfile.id,
          product: product,
          programId: this.program.id,
          buyWithCredits: buyWithCredits
        }
        this.unlockProgramSubscription.unsubscribe();
        this.paymentSubscription = this.paymentService.bookProgram(bookVodModel).subscribe(model => {
          this.spinner.hide();
          this.subscriptionModalType.next(SubscriptionModalType.UnlockedProgram);
          this.subscriptionPopupActive = true;
          document.getElementById("subscriptionModal").click();
          this.paymentSubscription.unsubscribe();
        }, (error: HttpErrorResponse) => {
          const notEnoughCreditsCode = "insufficient-credits";
          const missingCreditsPermissionCode = "missing-credits-permission";
          const userId = this.authService.currentProfile.id;
          const productId = product.pabblyProductId;
          const planId = product.pabblyPlanId;
          const url = `${environment.fitkitApi.url}/payment/create?productId=${productId}&planId=${planId}&userId=${userId}&vodProgramId=${this.program.id}`;
          this.subscriptionUrl.next(url);
          if (error.error.code == missingCreditsPermissionCode) {
            this.subscriptionModalType.next(SubscriptionModalType.PaymentWithFunds);
          } else if (error.error.code == notEnoughCreditsCode) {
            this.subscriptionModalType.next(SubscriptionModalType.PaymentPermission);
          } 
          this.subscriptionPopupActive = true;
          document.getElementById("subscriptionModal").click();
          this.spinner.hide();
          this.paymentSubscription.unsubscribe();
        });
      });
    } else {
      this.loginClick();
    }
  }
  
  loginClick() {
    document.getElementById("welcomeModal").click();
  }

  leaveFeedback() {
    document.getElementById("ratingsModal").click();
  }
  
  explorePrograms() {
    this.router.navigate(['home']);
  }

  openVideoDescription(index: number) {
    this.isDescriptionClick = true;
    this.clickedVideo.next(this.program.videos[index]);
    document.getElementById("descriptionModal").click();
  }

  private openVideo(index: number) {
    this.clickedVideoUrl.next(this.convertToSafeUrl(this.program.videos[index]));
    this.clickedVideoName.next(this.program.videos[index].name);
    document.getElementById("videoModal").click();
  }

  private loadProgram() {
    this.activatedRoute.paramMap.subscribe((data) => {
      this.programSubscription = this.vodService.getSingleProgramModel(data.get('id'))
      .subscribe(program => {
        if (program.teaser) {
          program.videos.unshift(this.generateTeaserVideo(program));
        }
        this.program = program;
        this.programObservable.next(program);
        this.isLoaded = true;
        this.programSubscription.unsubscribe();
        this.loadSubscription();
      });
    });
  }

  private loadSubscription() {
    if (!!this.authService.currentProfile) {
      this.getSubscription(this.authService.currentProfile.id);
    } else {
      this.authService.profileObserver.subscribe(profile => {
        this.getSubscription(profile.id);
      });
    }
  }

  private getSubscription(profileId: string) {
    this.subscriptionService.getSubscriptionForProgram(profileId, 
      this.program.id).subscribe(subscription => {
        this.isLocked = !subscription;
        if (!this.isLocked) {
          this.showSuccessfulSubscriptionPopupIfNeeded();
        }
      });
  }

  private convertToSafeUrl(video: Video): SafeResourceUrl {
    const vimeoUrl = "https://player.vimeo.com/video/";
    const vimeoOptions = "?autoplay=1&loop=1";
    const id = video.url.split("/");
    const url = vimeoUrl + id[id.length - 1] + vimeoOptions;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private observePaymentType() {
    this.paymentTypeSubscription = this.subscriptionService.paymentTypeSubject.subscribe(paymentType => {
      switch (paymentType) {
        case PaymentType.Money: 
          window.location.href = this.subscriptionUrl.getValue();
          break;
        case PaymentType.Credits:
          this.unlockProgram(true);
          this.subscriptionService.setPaymentType(PaymentType.Undefined);
          break;
        default:
          break;
      }
    });
  }

  private showSuccessfulSubscriptionPopupIfNeeded() {
    this.activatedRoute.queryParams.subscribe((data: BookVodResponseModel) => {
      if (Object.keys(data).length >= 3) {
        this.subscriptionModalType.next(SubscriptionModalType.UnlockedProgram);
        document.getElementById("subscriptionModal").click();
      }
    });
  }

  private generateTeaserVideo(program: ProgramModel): Video {
    return {
      id: "teaser",
      name: "Teaser Video",
      url: program.teaser,
      order: -1,
      programId: program.id,
      photoUrl: program.photoUrls[0]['photo']
    }
  }

  numClasses(): Number {
    if (this.program.videos[0].id == 'teaser') {
      return this.program.videos.length  - 1;
    }
    return this.program.videos.length;
  }
}
