<div *ngIf="isLoaded">
    <div *ngIf="subscriptions.length > 0" class="margin-top-96">
        <div class="container">
            <p class="h1 font-weight-bold col-md-6 col-sm-12">Здраво, {{authService.getFirstName()}}</p>
            <p class="lead mt-4 col-md-6 col-sm-12">Сите програми кои ги имаш отклучено можеш да ги најдеш овде. Ти посакуваме одлично искуство и многу пот.</p>
        </div>
        <div #programs class="margin-top-96">
            <div *ngFor="let program of subscriptions">
                <div class="container">
                    <p class="h1 font-weight-bold">{{program[0]['categoryName']}}</p>
                    <p class="lead">Во соработка со твоите омилени фит инструктори ти креиравме.</p>
                </div>
                <ngx-glide [perView]="1.25"
                    [showArrows]="false" 
                    [showBullets]="false"
                    [gap]="40"
                    focusAt="center">
                    <ng-container *ngFor="let innerProgram of program">
                        <div #containerChild (click)="onProgramClick(innerProgram)">
                            <p class="h1 position-absolute text-white bg-transparent program-name">{{innerProgram['name']}}</p>
                            <p *ngIf="innerProgram['videos'].length == 1" class="h4 position-absolute text-white bg-transparent program-author">со {{innerProgram['coachName']}} - {{innerProgram['videos'].length}}  тренинг - {{innerProgram['levelName']}}</p>
                            <p *ngIf="innerProgram['videos'].length > 1" class="h4 position-absolute text-white bg-transparent program-author">со {{innerProgram['coachName']}} - {{innerProgram['videos'].length}}  тренинзи - {{innerProgram['levelName']}}</p>
                            <div class="program-image-parent">
                                <img [src]="innerProgram['photoUrls'][0]['photo']" class="program-image">
                            </div>
                        </div>
                    </ng-container>
                </ngx-glide>
                <div class="mt-5"></div>
            </div>
        </div>
        <div class="container">
            <div class="col-12 margin-top-96">
                <button class="custom-button custom-button-primary border-0 feedback-button" (click)="explorePrograms()">
                    Откриј нови програми
                </button>
                <button class="custom-button custom-button-secondary-inverted ml-5 feedback-button">
                    Препорачај на другар
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="subscriptions.length == 0" class="container margin-top-96 text-center">
        <img src="../../../assets/images/explore_programs.png" class="explore-img">
        <p class="lead mt-5 mb-5">Моментално немаш отклучено<br>FitKit Plus програма.</p>
        <button class="custom-button custom-button-primary border-0" (click)="explorePrograms()">
            Откриј програми
        </button>
    </div>
    <div class="margin-top-96"><p class="invisible">Dummy content</p></div>
</div>