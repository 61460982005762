<button id="videoModal" class="d-none" (click)="open(videomodal)"></button>

<ng-template #videomodal let-modal>
    <div class="video-modal">
        <div class="video-wrapper-popup">
            <iframe [src]="videoUrl" frameborder="0" 
                title="{{videoName}}" webkitallowfullscreen 
                mozallowfullscreen allowfullscreen allow=autoplay></iframe>
        </div>
    </div>
</ng-template>