import { Component, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { CustomButtonComponent } from './components/custom-button/custom-button.component'
import { PopupComponent } from './components/popup/generic-popup/popup.component';
import { VideoPopupComponent } from './components/popup/video-popup/video-popup.component';
import { CustomButtonService } from './services/custom-button/custom-button.service'
import { PopupService } from './services/popup/popup.service';
import { VideoService } from './services/popup/video.service';
import { SubscriptionPopupService } from './services/popup/subscription.popup.service';
import { SubscriptionPopupComponent } from './components/popup/subscription.popup/subscription.popup.component';
import { ResetPasswordComponent } from './components/popup/reset-password/reset-password.component';
import { ResetPasswordResultComponent } from './components/popup/reset-password-result/reset-password-result.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(injector: Injector, 
              public customButtonService: CustomButtonService,
              public popupService: PopupService,
              public videoService: VideoService,
              public subscriptionPopupService: SubscriptionPopupService) {
    // Convert component to a custom element.
    const customButtonElement = createCustomElement(CustomButtonComponent, {injector});
    const popupElement = createCustomElement(PopupComponent, {injector});
    const videoElement = createCustomElement(VideoPopupComponent, {injector});
    const subscriptionElement = createCustomElement(SubscriptionPopupComponent, {injector});
    const resetPassElement = createCustomElement(ResetPasswordComponent, {injector});
    const resetPassResultElement = createCustomElement(ResetPasswordResultComponent, {injector});

    // Register the custom element with the browser.
    customElements.define('popup-element', popupElement);
    customElements.define('custom-button', customButtonElement);
    customElements.define('video-popup', videoElement);
    customElements.define('subscription-popup', subscriptionElement);
    customElements.define('reset-password-popup', resetPassElement);
    customElements.define('reset-password-result-popup', resetPassResultElement);
  }

  title = 'fitkitplus';
}
