<div *ngIf="isLoaded">
    <div>
        <ngx-glide [perView]="1" [showArrows]="false" [showBullets]="true" [gap]="0">
            <ng-container *ngFor="let photo of program.photoUrls">
                <div #containerChild>
                    <img [src]="photo['photo']" class="program-details-image">
                </div>
            </ng-container>
        </ngx-glide>
    </div>
    <div class="container">
        <div class="margin-top-96">
            <p class="h1 font-weight-bold">{{program.name}}</p>
            <p *ngIf="numClasses() == 1" class="lead">со {{program.coachName}} - {{numClasses()}}
                тренинг - {{program.levelName}}</p>
            <p *ngIf="numClasses() > 1" class="lead">со {{program.coachName}} - {{numClasses()}}
                тренинзи - {{program.levelName}}</p>
            <div class="mt-4">
                <p class="lead program-details-description">{{program.description.mk}}</p>
            </div>
        </div>
        <div *ngIf="program.neededTags.mk.length > 0 || program.unneededTags.mk.length > 0">
            <div class="margin-top-96">
                <p class="h1 font-weight-bold">Што ти треба за оваа програма?</p>
                <div *ngFor="let unneededTag of program.unneededTags.mk;" class="d-flex">
                    <img src="../../assets/images/no_need.png" class="equipment-icon">
                    <p class="lead tag-element">{{unneededTag.value}}</p>
                </div>
                <div *ngFor="let neededTag of program.neededTags.mk;" class="d-flex">
                    <img src="../../assets/images/need.png" class="equipment-icon">
                    <p class="lead tag-element">{{neededTag.value}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="isLocked" class="margin-top-96">
            <button class="custom-button custom-button-primary border-0" (click)="unlockProgram()">
                Отклучи ја програмата
            </button>
        </div>
        <div class="margin-top-96 program-classes-description">
            <p class="h1 font-weight-bold">Тренинзи</p>
            <p class="lead">Преглед на листата на тренинзи кои ќе бидат достапни со отклучувањето на оваа програма.</p>
        </div>
    </div>
    <div *ngFor="let video of program.videos; let i = index" class="program-classes">
        <hr *ngIf="i == 0">
        <div class="d-flex justify-content-between align-items-center program-videos" 
             (click)="onVideoClick(i)">
            <div class="d-flex align-items-center">
                <img src="{{video['photoUrl']}}" class="program-video-image" />
                <div class="program-video-name">
                    <p class="lead">{{video['name']}}</p>
                    <p *ngIf="video.description != undefined
                              && video.description['mk'] != undefined
                              && video.description['mk'].length" class="lead link"
                        (click)="openVideoDescription(i)">Види детален опис за тренингот</p>
                </div>
            </div>
            <img *ngIf="isLocked && video.id != 'teaser'" 
                 src="../../assets/images/locked_program.png" class="program-video-icon">
            <img *ngIf="!isLocked || video.id == 'teaser'" 
                 src="../../assets/images/play_program.png" class="program-video-icon">
        </div>
        <hr>
        <video-popup [videoUrl]="clickedVideoUrl.getValue()" 
            [videoName]="clickedVideoName.getValue()"></video-popup>
    </div>
    <div *ngIf="isLocked" class="container margin-top-150">
        <button class="custom-button custom-button-primary border-0 mr-5 feedback-button" (click)="unlockProgram()">
            Отклучи ја програмата
        </button>
        <button class="custom-button custom-button-secondary-inverted feedback-button" (click)="explorePrograms()">
            Откриј нови програми
        </button>
    </div>
    <div *ngIf="!isLocked" class="container margin-top-150">
        <button class="custom-button custom-button-primary border-0 mr-5 feedback-button" (click)="leaveFeedback()">
            Кажи ни импресии
        </button>
        <button class="custom-button custom-button-secondary-inverted feedback-button" (click)="explorePrograms()">
            Откриј нови програми
        </button>
    </div>
    <div class="margin-top-150"></div>
    <popup-element [popupType]="'description'" 
                   [video]="clickedVideo.getValue()" 
                   [header]="program.name"></popup-element>
    <subscription-popup *ngIf="subscriptionPopupActive" 
                        [program]="programObservable.getValue()" 
                        [type]="subscriptionModalType.getValue()" 
                        [url]="subscriptionUrl.getValue()"></subscription-popup>
</div>