<button id="loginModal" class="d-none" (click)="open(popupmodal, 'login')"></button>
<button id="ratingsModal" class="d-none" (click)="open(popupmodal, 'ratings')"></button>
<button id="descriptionModal" class="d-none" (click)="open(popupmodal, 'description')"></button>
<button id="welcomeModal" class="d-none" (click)="open(popupmodal, 'welcome')"></button>
<button id="registerModal" class="d-none" (click)="open(popupmodal, 'register')"></button>
<button id="successModal" class="d-none" (click)="open(popupmodal, 'success')"></button>

<ng-template #popupmodal let-modal class="modal">
    <div class="popup-close-parent">
        <img id="popup-close" class="popup-close float-right" src="../../../assets/images/popup_close.png" (click)="modal.close('Save click')">
    </div>
    <div class="popup-body">
        <!-- Logo -->
        <div *ngIf="popupType != 'description' 
                    && popupType != 'success'">
            <img src="../../../assets/images/logo.png" class="popup-logo">
        </div>
        <!-- Register -->
        <div *ngIf="popupType == 'register'">
            <p class="h1 modal-title margin-popup font-weight-bold text-center" id="modal-basic-title">Креирај профил</p>
            <form [formGroup]="registerInfo" novalidate>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input #firstElement type="text" formControlName="name" placeholder="Име" class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
                </div>
                <div *ngIf="registerInfo.get('name').touched && registerInfo.get('name').hasError('required')" class="help-block">Мора да внесеш име.</div>
                <div *ngIf="registerInfo.get('name').touched && !registerInfo.get('name').hasError('required') && registerInfo.get('name').invalid" class="help-block">Името мора да се состои само од букви.</div>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input type="text" formControlName="surname" placeholder="Презиме" class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
                </div>
                <div *ngIf="registerInfo.get('surname').touched && registerInfo.get('surname').hasError('required')" class="help-block">Мора да внесеш презиме.</div>
                <div *ngIf="registerInfo.get('surname').touched && !registerInfo.get('surname').hasError('required') && registerInfo.get('surname').invalid" class="help-block">Презимето мора да се состои само од букви.</div>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input type="text" formControlName="city" placeholder="Град" class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
                </div>
                <div *ngIf="registerInfo.get('city').touched && registerInfo.get('city').hasError('required')" class="help-block">Мора да внесеш град.</div>
                <div *ngIf="registerInfo.get('city').touched && !registerInfo.get('city').hasError('required') && registerInfo.get('city').invalid" class="help-block">Името на градот мора да се состои само од букви.</div>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input type="email" formControlName="email" placeholder="E-mail адреса" 
                    class="form-control popup-input" aria-label="Large" 
                    aria-describedby="inputGroup-sizing-lg" (ngModelChange)="onEmailCharChange($event)">
                </div>
                <div *ngIf="registerInfo.get('email').touched 
                            && registerInfo.get('email').hasError('required')" class="help-block">Мора да внесеш e-mail.</div>
                <div *ngIf="registerInfo.get('email').touched 
                            && !registerInfo.get('email').hasError('required') 
                            && registerInfo.get('email').invalid" class="help-block">Невалиден e-mail.</div>
                <div *ngIf="registerInfo.get('email').touched 
                            && !registerInfo.get('email').hasError('required') 
                            && !registerInfo.get('email').invalid
                            && !registrationEmailIsValid" class="help-block">Тој e-mail е веќе зафатен.</div>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input type="password" formControlName="password" placeholder="Лозинка" class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
                </div> 
                <div *ngIf="registerInfo.get('password').touched && registerInfo.get('password').hasError('required')" class="help-block">Мора да внесеш лозинка.</div>
                <div *ngIf="registerInfo.get('password').touched && !registerInfo.get('password').hasError('required') && registerInfo.get('password').invalid" class="help-block">Лозинката мора има барем 6 карактери.</div>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input type="password" formControlName="confirmPassword" placeholder="Потврди ја лозинката" class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
                </div>
                <div *ngIf="registerInfo.get('confirmPassword').touched && registerInfo.get('confirmPassword').hasError('required')" class="help-block">Мора да внесеш потврдна лозинка.</div>
                <div *ngIf="registerInfo.get('confirmPassword').touched && !registerInfo.get('confirmPassword').hasError('required') && registerInfo.get('confirmPassword').invalid" class="help-block">Лозинката мора има барем 6 карактери.</div>
                <div *ngIf="registerInfo.get('confirmPassword').touched && !registerInfo.get('confirmPassword').hasError('required') && !registerInfo.get('confirmPassword').invalid && registerInfo.hasError('ConfirmPassword')" class="help-block">Лозинките мора да се совпаѓаат.</div>
                <div class="margin-small d-flex justify-content-between align-items-center add-photo" (click)="addPhoto()">
                    <img src="{{profileIcon.getValue()}}" class="profile-icon">
                    <button class="custom-button custom-button-secondary-inverted add-photo-button">
                        Прикачи слика
                        <input type="file" id="popup-file" class="d-none" accept="image/*" (change)="onFileChange($event)">
                    </button>
                </div>
                <div *ngIf="profileIconUploadFail" class="help-block">Проблем со прикачување на профилна слика. Пробај одново.</div>
                <div *ngIf="firebaseFail" class="help-block">Проблем со креирање на профилот. Пробај одново.</div>
                <div class="text-center margin-popup-medium">
                    <button class="custom-button custom-button-primary border-0 shadow-nonе" [disabled]="registerInfo.invalid" (click)="registerUser()">
                        Креирај го профилот
                    </button>
                </div>
            </form>
        </div>
        <!-- Login -->
        <div *ngIf="popupType == 'login'">
            <p class="h1 modal-title margin-popup font-weight-bold text-center" id="modal-basic-title">Логирај се</p>
            <form [formGroup]="loginInfo" novalidate>
                <div class="input-group input-group-lg margin-popup-medium">
                    <input #firstElement type="email" formControlName="email" 
                    placeholder="E-mail адреса" class="form-control popup-input" 
                    aria-label="Large" aria-describedby="inputGroup-sizing-lg"
                    (ngModelChange)="onFitkitLoginChange()">
                </div>
                <div *ngIf="loginInfo.get('email').touched 
                            && loginInfo.get('email').hasError('required')" 
                            class="help-block">Мора да внесеш e-mail.</div>
                <div *ngIf="loginInfo.get('email').touched 
                            && !loginInfo.get('email').hasError('required') 
                            && loginInfo.get('email').invalid" 
                            class="help-block">Невалиден e-mail.</div>
                <div class="input-group input-group-lg margin-popup-xsmall">
                    <input type="password" formControlName="password" placeholder="Лозинка" 
                    class="form-control popup-input" aria-label="Large" 
                    aria-describedby="inputGroup-sizing-lg" (ngModelChange)="onFitkitLoginChange()">
                </div> 
                <div *ngIf="loginInfo.get('password').touched 
                            && loginInfo.get('password').hasError('required')" 
                            class="help-block">Мора да внесеш лозинка.</div>
                <div *ngIf="loginInfo.get('password').touched 
                            && !loginInfo.get('password').hasError('required') 
                            && loginInfo.get('password').invalid" 
                            class="help-block">Лозинката мора да има барем 6 карактери.</div>
                <div class="form-check margin-popup-small">
                    <input class="form-check-input" type="checkbox" 
                            [ngModel]="cacheLogin"
                            [ngModelOptions]="{standalone: true}" 
                            (change)="onCacheChange($event)">
                    <label class="form-check-label save-login-label" for="saveLogin">
                    Зачувај ме најавен/а
                    </label>
                </div>
                <div *ngIf="invalidFitkitLogin" class="help-block">Неуспешно логирање.</div>
                <div class="text-center margin-popup">
                    <button class="custom-button custom-button-primary border-0 shadow-nonе" [disabled]="loginInfo.invalid" (click)="basicLogin()">
                        Логирај ме
                    </button>
                </div>
            </form>
            <div class="text-center margin-popup-medium">
                <span class="lead">Ја заборавив лозинката. <span class="lead link" (click)="help()">Помош!</span></span><br>
                <span class="lead">Немам ФитКит профил. <span class="lead link" (click)="createProfile()">Креирај профил.</span></span>
            </div>
        </div>
        <!-- Welcome -->
        <div *ngIf="popupType == 'welcome'">
            <p class="h1 modal-title margin-popup font-weight-bold text-center" id="modal-basic-title">Добредојде!</p>
            <div class="margin-popup text-center">
                <button class="custom-button custom-button-primary border-0 create-profile-button" (click)="createProfile()"><span class="lead">Креирај профил</span></button>
            </div>
            <div class="margin-popup-xsmall text-center">
                <button class="custom-button custom-button-secondary-inverted facebook-login-button" (click)="loginWithFacebook()"><span class="lead">Facebook логин</span></button>
            </div>
            <p class="lead text-center margin-popup-medium">Веќе си дел од ФитКит фамилијата?</p>
            <div class="margin-popup-xsmall text-center">
                <button class="custom-button custom-button-secondary-inverted basic-login-button" (click)="openBasicLogin()"><span class="lead">Логирај се</span></button>
            </div>
        </div>
        <!-- Program description -->
        <div *ngIf="popupType == 'description'">
            <p class="h2 modal-title font-weight-bold" id="modal-basic-title">{{header}}</p>
            <p class="h4 modal-title font-weight-bold" id="modal-basic-title">Опис на "{{video.name}}"</p>
            <p *ngIf="video.description != undefined
                      && video.description['mk'] != undefined
                      && video.description['mk'].length" 
                      class="lead margin-popup-medium">{{video.description['mk']}}</p>
        </div>
        <!-- Ratings -->
        <div *ngIf="popupType == 'ratings'">
            <p class="h1 modal-title margin-popup font-weight-bold" id="modal-basic-title">{{header}}</p>
            <p class="lead margin-popup popup-description" id="modal-basic-title" [innerHtml]="description"></p>
            <div class="text-center">
                <ngb-rating [max]=5 [(rate)]="rating" class="margin-popup">
                    <ng-template let-fill="fill">
                        <span class="star" [ngClass]="'color'"[class.filled]="fill === 100">&#9733;</span>
                    </ng-template>
                </ngb-rating>
            </div>
            <div class="margin-popup text-center">
                <button class="custom-button custom-button-primary border-0 shadow-nonе" [disabled]="rating == 0" (click)="sendRating()">
                    Испрати рејтинг
                </button>
            </div>
        </div>
        <!-- Changes saved -->
        <div *ngIf="popupType == 'success'">
            <p class="h1 modal-title text-center margin-popup font-weight-bold" 
                id="modal-basic-title">Честитки!</p>
            <p class="lead margin-popup text-center" 
                id="modal-basic-title">Промените се зачувани.</p>
        </div>
    </div>
</ng-template>
<reset-password-popup></reset-password-popup>