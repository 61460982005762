import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, Subject } from 'rxjs';
import { LoginInfo } from 'src/app/components/data/login_info';
import { Profile } from 'src/app/components/data/profile/profile';
import { FacebookUser } from 'src/app/components/data/facebook_login/facebook_user';
import { RegisterInfo } from 'src/app/components/data/register/register_info';
import { AuthUpdateResult } from 'src/app/components/data/profile/auth_update_result';
import { FacebookLoginResponse } from 'src/app/components/data/facebook_login/login_response';
import * as firebase from 'firebase';
import { CityService } from '../city/city.service';
import { FirebaseApp } from '@angular/fire';
import { ProfileUpdateError } from 'src/app/components/data/profile/profile_update_error';
import { RegisterCity } from 'src/app/components/data/register/register_city';
import { ProfileUpdateType } from 'src/app/components/data/profile/profile_update_type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  public profileObserver: Subject<Profile> = new Subject<Profile>();
  public currentProfile: Profile;
  public CACHED_PROFILE_KEY = "currentProfile";
  public cachedProfile = localStorage.getItem(this.CACHED_PROFILE_KEY);
  public cacheLogin = false;

  constructor(private afAuth: AngularFireAuth,
              private db: AngularFireDatabase,
              private firebaseApp: FirebaseApp,
              private cityService: CityService) { 
    this.initFacebookLibrary();
    this.initCachedUser();
  }

  //#region FitKit account login/register
  initCachedUser() {
    let cachedProfile: Profile = JSON.parse(localStorage.getItem(this.CACHED_PROFILE_KEY));
    this.profileObserver.next(cachedProfile);
    this.currentProfile = cachedProfile;
  }

  logInWithFitKitAccount(email, password): Observable<LoginInfo> {
    return new Observable<LoginInfo>(observer => {
      this.afAuth.signInWithEmailAndPassword(email, password)
      .then(_ => {
        //successful log-in

        this.getProfileWithEmail(email).subscribe(profile => {
          let loginInfo: LoginInfo = {
            email: email, 
            success: profile != null, 
            profile: profile,
            error: profile == null ? "Profile is null" : null
          }
          
          observer.next(loginInfo);
          this.saveCurrentProfile(profile);
        });
      }).catch((error) => {
        let errorReturn : LoginInfo = {
          email: email, 
          success: false,
          error: error
        }
        observer.next(errorReturn);
      })
    });
  }

  private createFitkitProfile(profile: Profile): Observable<Profile> {
    return new Observable<Profile>(observer => {
      let writes = {};
      writes['/profile/' + profile.id] = profile;
      this.db.object('/').update(writes).then(success => {
        observer.next(profile);
      }).catch(error => {
        observer.next(null);
      });
    });
  }

  getProfileWithEmail(email: string): Observable<Profile> {
    return new Observable<Profile>(observer => {
      this.db.list("profile").valueChanges().subscribe(profiles => {
        var profileToReturn : Profile = null;
        profiles.forEach((profile: Profile) => {
          if (profile.email == email) {
            profileToReturn = profile;
          }
        });
        observer.next(profileToReturn);
      });
    });
  }

  registrationEmailIsValid(email: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.getProfileWithEmail(email).subscribe(profile => {
        observer.next(profile == null);
      });
    });
  }

  logoutUser() {
    this.profileObserver.next(null);
    this.currentProfile = null;
    localStorage.removeItem(this.CACHED_PROFILE_KEY);
  }

  private saveCurrentProfile(profile: Profile) {
    this.profileObserver.next(profile);
    this.currentProfile = profile;

    if (this.cacheLogin) {
      localStorage.setItem(this.CACHED_PROFILE_KEY, JSON.stringify(profile));
    }
  }

  updateCurrentProfile(profile: Profile) {
    this.profileObserver.next(profile);
    this.currentProfile = profile;

    if (this.cacheLogin || localStorage.getItem(this.CACHED_PROFILE_KEY) != null) {
      localStorage.setItem(this.CACHED_PROFILE_KEY, JSON.stringify(profile));
    }
  }

  registerUser(registerInfo: RegisterInfo): Observable<AuthUpdateResult> {
    return new Observable<AuthUpdateResult>(observer => {
      this.registrationEmailIsValid(registerInfo.email).subscribe(isEmailValid => {
        if (!isEmailValid) {
          observer.next(({
            success: false,
            error: ProfileUpdateError.EMAIL_TAKEN,
            type: ProfileUpdateType.CREATE
          }));
        } else {
          this.registerFitKitProfile(registerInfo).subscribe(registerResult => {
            observer.next(registerResult);
          });
        }
      });
    });
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  private registerFitKitProfile(registerInfo: RegisterInfo): Observable<AuthUpdateResult> {
    return new Observable<AuthUpdateResult>(observer => {
      this.afAuth.createUserWithEmailAndPassword(registerInfo.email, registerInfo.password).then(firebaseUser => {
        this.uploadProfileImage(firebaseUser.user.uid, registerInfo.picture).subscribe(downloadURL => {
          if (downloadURL == null) {
            observer.next({
              success: false,
              error: ProfileUpdateError.PHOTO_UPLOAD_FAIL,
              type: ProfileUpdateType.CREATE
            });
          } else {
            this.getSelectedCityId(registerInfo.city).subscribe(city => {
              this.createFitKitProfile(firebaseUser, registerInfo, city, downloadURL).subscribe(registerResult => {
                observer.next(registerResult);
              });            
            });
          }
        });
      }).catch(firebaseError => {
        observer.next(({
          success: false,
          error: ProfileUpdateError.FIREBASE_ERROR,
          type: ProfileUpdateType.CREATE
        }));
      });
    });
  }

  private createFitKitProfile(firebaseUser: firebase.auth.UserCredential,
                              registerInfo: RegisterInfo, 
                              city: RegisterCity,
                              imageUrl): Observable<AuthUpdateResult> {
    return new Observable<AuthUpdateResult>(observer => {
      const profile: Profile = {
        id: firebaseUser.user.uid,
        email: registerInfo.email,
        enablePushNotifications: true,
        firstName: registerInfo.firstName,
        lastName: registerInfo.lastName,
        name: registerInfo.name + " " + registerInfo.lastName,
        cityInput: city.userCity,
        selectedCityId: city.city.key,
        photoUrl: imageUrl
      }
      this.createFitkitProfile(profile).subscribe(profileResult => {
        if (profileResult == null) {
          observer.next({
            success: false,
            error: ProfileUpdateError.FIREBASE_ERROR,
            type: ProfileUpdateType.CREATE
          });
        } else {
          observer.next({
            success: true,
            profile: profile,
            type: ProfileUpdateType.CREATE
          });
        }
      })
    });
  }

  //returns profile image location
  uploadProfileImage(profileId: string, file?: File): Observable<string> {
    if (file == null) {
      return new Observable<string>(observer => {
        observer.next("");
      });
    }

    return new Observable<string>(observer => {
      const path = `/profile_photos/${profileId}/${this.generateRandomString(12)}.jpg`;
      const storageRef = this.firebaseApp.storage().ref().child(path);
      var uploadTask = storageRef.put(file);
      
      //Docs: https://firebase.google.com/docs/storage/web/upload-files
      uploadTask.on("state_changed", snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      }, error => {
        // Handle unsuccessful uploads
        observer.next(null);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          observer.next(downloadURL);
        });
      });
    });
  }

  private getSelectedCityId(cityInput: string): Observable<RegisterCity> {
    return new Observable<RegisterCity>(observer => {
      this.cityService.getCityWithName(this.transliterateWord(cityInput)).subscribe(city => {
        observer.next(city);
      });
    });
  }
  //#endregion

  //#region Facebook login
  logInWithFacebook(): Observable<Profile> {
    return new Observable<Profile>(observer => {
      this.getFacebookProfile().subscribe(userInfo => {
        if (userInfo == null) {
          observer.next(null);
          return;
        }
        const facebookCredential = firebase.auth.FacebookAuthProvider
                                   .credential(userInfo.response.authResponse.accessToken);
        firebase.auth().signInWithCredential(facebookCredential).then(userCredential => {
          let id = userCredential.user.uid;
          userInfo.id = id;
          this.createOrReturnExistingUser(userInfo).subscribe(profile => {
            profile.facebookToken = userInfo.response.authResponse.accessToken;
            this.saveCurrentProfile(profile);
            observer.next(profile);
          });
        });
      });
    });
  }

  private initFacebookLibrary() {
    (window as any).fbAsyncInit = function() {
      window['FB'].init({
        appId      : '1799670403583358',
        cookie     : true,
        xfbml      : true,
        version    : 'v3.1',
      });
      window['FB'].AppEvents.logPageView();
    };
 
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }

  private getFacebookProfile(): Observable<FacebookUser> {
    return new Observable<FacebookUser>(observer => {
      window['FB'].login((response: FacebookLoginResponse) => {
        console.log('login response', response);
        if (response.authResponse) {
          window['FB'].api('/me', {
            fields: 'last_name, first_name, email, picture.width(500)'
          }, (userInfo: FacebookUser) => {
            userInfo.response = response;
            return observer.next(userInfo);
          });
        } else {
          console.log('User login failed');
          return observer.next(null);
        }
      }, {scope: 'email, public_profile'});
    });
  }

  private createOrReturnExistingUser(userInfo: FacebookUser): Observable<Profile> {
    return new Observable<Profile>(observer => {
      this.db.object('profile/' + userInfo.id).valueChanges().subscribe((profile: Profile) => {
        if (profile == null) {
          console.log("Profile doesn't exist. Create new one!");
          let newProfile : Profile = {
            id: userInfo.id,
            name: userInfo.first_name + " " + userInfo.last_name,
            firstName: userInfo.first_name, 
            lastName: userInfo.last_name,
            email: userInfo.email,
            enablePushNotifications: true,
            photoUrl: userInfo.picture.data.url,
            selectedCityId: '-Lb5GrFwZMtM3EwFJmau', // Hardcoded to Skopje like the mobile app
          }
          this.createFitkitProfile(newProfile).subscribe(profile => {
            observer.next(profile);
          });
        } else {
          console.log("Profile already exists. Return this one.");
          observer.next(profile);
        }
      });
    });
  }
  //#endregion

  //#region helper methods
  //Used to generate a random string for profile picture
  private generateRandomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i = i + 1) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
  }

  private transliterateWord(word: string) {
    var a = {"Dzh":"Џ","dzh":"џ","Gj":"Ѓ","ѓ":"gj","Dz":"S","dz":"ѕ","Lj":"Љ","lj":"љ","Nj":"Њ","nj":"њ","Kj":"Ќ","kj":"ќ","Ch":"Ч","ch":"ч","C":"Ц","U":"У","K":"К","E":"Е","N":"Н","G":"Г","Sh":"Ш","Z":"З","H":"Х","c":"ц","u":"у","k":"к","e":"е","n":"н","g":"г","sh":"ш","z":"з","h":"х","F":"Ф","V":"В","A":"А","P":"П","R":"Р","O":"О","L":"Л","D":"Д","ZH":"Ж","f":"ф","v":"в","a":"а","p":"п","r":"р","o":"о","l":"л","d":"д","zh":"ж","S":"С","M":"М","I":"И","Т":"T","B":"Б","s":"с","m":"м","i":"и","t":"т","b":"б"};

    return word.split('').map(char => { 
      return a[char] || char; 
    }).join("");
  }

  getFirstName(): string {
    if (this.currentProfile != null) {
      if (this.currentProfile.firstName != null 
          && this.currentProfile.firstName.length > 0) {
        return this.currentProfile.firstName;
      } else {
        return this.currentProfile.name.split(" ")[0];
      }
    }
  }

  getLastName(): string {
    if (this.currentProfile != null) {
      if (this.currentProfile.lastName != null 
          && this.currentProfile.lastName.length > 0) {
        return this.currentProfile.lastName;
      } else {
        let splittedName = this.currentProfile.name.split(" ");
        if (splittedName.length > 1) {
          return this.currentProfile.name.split(" ")[1];
        }
        return "";
      }
    }
  }
  //#endregion
}