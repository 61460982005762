<div class="container margin-top-96">
    <div class="col-6">
        <p class="h1 font-weight-bold">Здраво, {{authService.getFirstName()}}</p>
        <div class="margin-medium d-flex justify-content-between align-items-center add-photo" 
             (click)="addPhoto()">
            <img src="{{profileIcon.getValue()}}" class="profile-icon">
            <button class="custom-button custom-button-secondary-inverted ml-4 change-image-button">
                Промени слика
                <input #fileInput type="file" id="profile-file" class="d-none"
                       accept="image/*" (click)="fileInput.value = null" 
                       (change)="onFileChange($event)">
            </button>
            <img *ngIf="isLoggedIn && profileIcon.getValue() != profile.photoUrl" 
                        src="../../../assets/images/no_need.png"
                class="remove-profile-icon" (click)="removeProfileIcon()" />
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="margin-medium">
            <p class="lead profile-options" (click)="setChangeName()">Промени име</p>
            <form [formGroup]="nameInfo" novalidate>
                <div *ngIf="changeName">
                    <input type="text" formControlName="name" placeholder="Внеси име"
                        value="{{authService.getFirstName()}}" class="form-control popup-input" aria-label="Large"
                        aria-describedby="inputGroup-sizing-lg">
                    <div *ngIf="nameInfo.get('name').touched 
                                    && nameInfo.get('name').hasError('required')" class="help-block">Мора да внесеш
                        име.</div>
                    <div *ngIf="nameInfo.get('name').touched 
                                    && !nameInfo.get('name').hasError('required') 
                                    && nameInfo.get('name').invalid" class="help-block">Името мора да се состои само од
                        букви.</div>
                    <input type="text" formControlName="surname" placeholder="Внеси презиме"
                        value="{{authService.getLastName()}}" class="form-control popup-input" aria-label="Large"
                        aria-describedby="inputGroup-sizing-lg">
                    <div *ngIf="nameInfo.get('surname').touched 
                            && nameInfo.get('surname').hasError('required')" class="help-block">Мора да внесеш презиме.
                    </div>
                    <div *ngIf="nameInfo.get('surname').touched 
                            && !nameInfo.get('surname').hasError('required') 
                            && nameInfo.get('surname').invalid" class="help-block">Презимето мора да се состои само од
                        букви.</div><br>
                </div>
            </form>
            <p class="lead profile-options" (click)="setChangeEmail()">Промени email адреса</p>
            <form [formGroup]="emailInfo" novalidate>
                <div *ngIf="changeEmail">
                    <p class="text-muted help-block">
                        Доколку сакате да ја промените вашата email адреса,
                        внесете ја постоечката лозинка, а потоа посакуваната адреса
                    </p>
                    <input type="password" formControlName="emailPassword" placeholder="Внеси лозинка"
                        class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
                    <div *ngIf="emailInfo.get('emailPassword').touched 
                            && emailInfo.get('emailPassword').hasError('required')" class="help-block">Мора да внесеш
                        лозинка.</div>
                    <div *ngIf="emailInfo.get('emailPassword').touched 
                            && !emailInfo.get('emailPassword').hasError('required') 
                            && emailInfo.get('emailPassword').invalid" class="help-block">Лозинката мора има барем 6
                        карактери.</div>
                    <div *ngIf="emailInfo.get('emailPassword').touched 
                        && !emailInfo.get('emailPassword').hasError('required') 
                        && !emailInfo.get('emailPassword').invalid
                        && emailInfo.hasError('EMAIL_CREDENTIALS_FAILED')" class="help-block">Погрешна лозинка</div>
                    <input type="email" formControlName="email" placeholder="Внеси нова e-mail адреса"
                        class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg"
                        (ngModelChange)="onEmailChange()">
                    <div *ngIf="emailInfo.get('email').touched 
                                    && emailInfo.get('email').hasError('required')" class="help-block">Мора да внесеш
                        e-mail.</div>
                    <div *ngIf="emailInfo.get('email').touched 
                                    && !emailInfo.get('email').hasError('required') 
                                    && emailInfo.get('email').invalid" class="help-block">Невалиден e-mail.</div>
                    <div *ngIf="emailInfo.hasError('EMAIL_TAKEN')"
                                    class="help-block">Тој e-mail е веќе зафатен.</div>
                    <br>
                </div>
            </form>
            <p class="lead profile-options" (click)="setChangePassword()">Промени лозинка</p>
            <form [formGroup]="passwordInfo" novalidate>
                <div *ngIf="changePassword">
                    <p class="text-muted help-block">
                        Доколку сакате да ја промените вашата лозинка,
                        внесете ја постоечката, а потоа новата
                    </p>
                    <input type="password" formControlName="currentPassword" 
                        placeholder="Внеси постоечка лозинка"
                        class="form-control popup-input" 
                        aria-label="Large" 
                        aria-describedby="inputGroup-sizing-lg"
                        (ngModelChange)="onPasswordInfoOldPassChange()">
                    <div *ngIf="passwordInfo.get('currentPassword').touched 
                                    && passwordInfo.get('currentPassword').hasError('required')" class="help-block">
                        Мора да внесеш постоечка лозинка.</div>
                    <div *ngIf="passwordInfo.get('currentPassword').touched 
                                    && !passwordInfo.get('currentPassword').hasError('required') 
                                    && passwordInfo.get('currentPassword').invalid" class="help-block">Лозинката мора
                        има барем 6 карактери.</div>
                    <div *ngIf="passwordInfo.get('currentPassword').touched 
                        && !passwordInfo.get('currentPassword').hasError('required') 
                        && !passwordInfo.get('currentPassword').invalid
                        && passwordInfo.hasError('PASSWORD_CREDENTIALS_FAILED')" class="help-block">Погрешна лозинка</div>
                    <input type="password" formControlName="password" placeholder="Внеси нова лозинка"
                        value="{{profile.password}}" class="form-control popup-input" aria-label="Large"
                        aria-describedby="inputGroup-sizing-lg">
                    <div *ngIf="passwordInfo.get('password').touched 
                                    && passwordInfo.get('password').hasError('required')" class="help-block">Мора да
                        внесеш лозинка.</div>
                    <div *ngIf="passwordInfo.get('password').touched 
                                    && !passwordInfo.get('password').hasError('required') 
                                    && passwordInfo.get('password').invalid" class="help-block">Лозинката мора има
                        барем 6 карактери.</div>
                    <input type="password" formControlName="confirmPassword" placeholder="Потврди лозинка"
                        value="{{profile.password}}" class="form-control popup-input" aria-label="Large"
                        aria-describedby="inputGroup-sizing-lg">
                    <div *ngIf="passwordInfo.get('confirmPassword').touched 
                                    && passwordInfo.get('confirmPassword').hasError('required')" class="help-block">
                        Мора да внесеш потврдна лозинка.</div>
                    <div *ngIf="passwordInfo.get('confirmPassword').touched 
                                    && !passwordInfo.get('confirmPassword').hasError('required') 
                                    && passwordInfo.get('confirmPassword').invalid" class="help-block">Лозинката мора
                        има барем 6 карактери.</div>
                    <div *ngIf="passwordInfo.get('confirmPassword').touched 
                                    && !passwordInfo.get('confirmPassword').hasError('required') 
                                    && !passwordInfo.get('confirmPassword').invalid 
                                    && passwordInfo.hasError('ConfirmPassword')" class="help-block">Лозинките мора да
                        се совпаѓаат.</div>
                </div>
            </form>
            <!-- <p class="lead profile-options">Промени го јазикот</p> -->
        </div>
    </div>
    <div class="col-12 margin-top-96 mb-5">
        <button class="custom-button custom-button-primary border-0" 
                [disabled]="nameInfo.invalid 
                            && emailInfo.invalid 
                            && passwordInfo.invalid
                            && profileIcon.getValue() == profile.photoUrl" 
                (click)="updateProfile()">
            Зачувај промени
        </button>
        <button class="custom-button custom-button-secondary-inverted ml-5" (click)="logout()">
            Одјави ме
        </button>
    </div>

</div>