export const environment = {
  production: false,
  apiKey: 'AIzaSyBeYsIusREhHQfNBNEnTxDkyDsHS2xH_jY',
  authDomain: 'fitkitplus-staging.firebaseapp.com',
  databaseURL: 'https://fitkit-staging.firebaseio.com',
  storageBucket: 'fitkit-staging.appspot.com',
  messagingSenderId: '218150982373',
  universalUrl: 'https://go.staging.fitkit.app',
  fitkitApi: {
    url: 'https://api.staging.fitkit.app',
  },
};
