import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PageNavigation } from '../data/page_navigation';
import { Profile } from '../data/profile/profile';
import { ProfileUpdate } from '../data/profile/profile_update';
import { ProfileUpdateResult } from '../data/profile/profile_update_result';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public profile: Profile = this.authService.currentProfile;
  public isLoggedIn = this.profile != null;
  public nameInfo: FormGroup;
  public emailInfo: FormGroup;
  public passwordInfo: FormGroup;
  public changeName = false;
  public changeEmail = false;
  public changePassword = false;
  public profileIcon = new BehaviorSubject<string>("");

  private isRemoveProfileIconClicked = false;
  private imageFile: File = null;
  private profileIsUpdatingSubscription: Subscription;
  private profileUpdateSubscription: Subscription;

  constructor(public authService: AuthService,
              private navigationService: NavigationService,
              private profileService: ProfileService,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.createProfileInfo();
    if (!this.isLoggedIn) {
      this.router.navigate(['/home']);
      return;
    }
    this.navigationService.setCurrentPage(PageNavigation.MY_PROFILE);
    this.profileIsUpdatingSubscription = this.profileService.isUpdating.subscribe(isUpdating => {
      if (isUpdating) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    this.profileIcon.next(this.profile.photoUrl);
  }

  ngOnDestroy(): void {
    this.profileIsUpdatingSubscription.unsubscribe();
    if (this.profileUpdateSubscription != null) {
      this.profileUpdateSubscription.unsubscribe();
    }
  }

  private createProfileInfo() {
    this.nameInfo = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zАБВГДЃЕЖЗSИЈКЛЉМНЊОПРСТЌУФХЦЧЏШабвгдѓежзѕијклљмнњопрстќуфхцчџш]+$')]),
      surname: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zАБВГДЃЕЖЗSИЈКЛЉМНЊОПРСТЌУФХЦЧЏШабвгдѓежзѕијклљмнњопрстќуфхцчџш]+$')]),
    });

    this.emailInfo = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      emailPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
    });

    this.passwordInfo = new FormGroup({
      currentPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    },{
      validators: [this.MatchPassword]
    });
  }

  private MatchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('confirmPassword').value;

    if (password != confirmPassword) {
      return { ConfirmPassword: true };
    } else {
        return null
    }
  }

  logout() {
    this.authService.logoutUser();
    this.router.navigate(['/']);
  }

  addPhoto() {
    if (this.isRemoveProfileIconClicked) {
      this.isRemoveProfileIconClicked = false;
      return;
    }
    document.getElementById("profile-file").click();
  }

  setChangeName() {
    this.changeName = !this.changeName;
    if (!this.changeName) {
      this.nameInfo.setValue({
        name: "",
        surname: ""
      });
    } 
  }

  setChangeEmail() {
    this.changeEmail = !this.changeEmail;
    if (!this.changeEmail) {
      this.emailInfo.setValue({
        email: "",
        emailPassword: ""
      });
    }
  }

  setChangePassword() {
    this.changePassword = !this.changePassword;
    if (!this.changePassword) {
      this.passwordInfo.setValue({
        password: "",
        confirmPassword: "",
        currentPassword: ""
      });
    }
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files as File[];
      if (file.type.startsWith('image')) {
        let reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
        this.imageFile = file;
      }
    }
  }

  private handleReaderLoaded(e) {
    var reader = e.target;
    this.profileIcon.next(reader.result);
  }

  removeProfileIcon() {
    this.isRemoveProfileIconClicked = true;
    this.profileIcon.next(this.profile.photoUrl);
    this.imageFile = null;
  }

  onEmailChange() {
    this.emailInfo.setErrors(null);
  }

  onEmailPasswordChange() {
    this.emailInfo.setErrors(null);
  }

  onPasswordInfoOldPassChange() {
    this.passwordInfo.setErrors(null);
  }

  updateProfile() {
    var profileUpdate: ProfileUpdate = {
      photo: this.imageFile,
      name: {
        name: this.nameInfo.value.name,
        surname: this.nameInfo.value.surname
      },
      email: {
        password: this.emailInfo.value.emailPassword,
        email: this.emailInfo.value.email
      },
      password: {
        currentPassword: this.passwordInfo.value.currentPassword,
        password: this.passwordInfo.value.password,
        confirmPassword: this.passwordInfo.value.confirmPassword
      }
    }
    this.profileUpdateSubscription = this.profileService.startUpdate(profileUpdate).subscribe(([imageResult, 
      nameResult,
      emailResult,
      passwordResult]) => {
      let profileUpdateResult: ProfileUpdateResult = {
        image: {
          success: false
        },
        name: {
          success: false
        }, 
        email: {
          success: false,
          error: ""
        },
        password: {
          success: false,
          error: ""
        }
      };
      profileUpdateResult.image.success = imageResult != null;
      profileUpdateResult.name.success = nameResult != null;
      profileUpdateResult.email.success = emailResult == "success";
      profileUpdateResult.email.error = emailResult;
      profileUpdateResult.password.success = passwordResult == "success";
      profileUpdateResult.password.error = passwordResult;

      if (profileUpdateResult.image.success 
        && profileUpdateResult.name.success
        && profileUpdateResult.email.success
        && profileUpdateResult.password.success) {
        this.createProfileInfo();
        this.removeProfileIcon();
        this.changeEmail = false;
        this.changeName = false;
        this.changePassword = false;
        this.isRemoveProfileIconClicked = false;
        document.getElementById("successModal").click();
      }

      if (!profileUpdateResult.image.success) {
        //TODO
      }

      if (!profileUpdateResult.name.success) {
        //TODO
      }

      if (!profileUpdateResult.email.success) {
        if (profileUpdateResult.email.error == "EMAIL_TAKEN") {
          this.emailInfo.setErrors({
            "EMAIL_TAKEN": true
          });
        }
        if (profileUpdateResult.email.error == "EMAIL_CREDENTIALS_FAILED") {
          this.emailInfo.setErrors({
            "EMAIL_CREDENTIALS_FAILED": true
          });
        }
      }

      if (!profileUpdateResult.password.success) {
        if (profileUpdateResult.password.error == "PASSWORD_CREDENTIALS_FAILED") {
          this.passwordInfo.setErrors({
            "PASSWORD_CREDENTIALS_FAILED": true
          });
        }
      }
    });
  }
}