import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Category } from 'src/app/components/data/category';
import { Program } from 'src/app/components/data/program';
import { AngularFireDatabase } from '@angular/fire/database';
import { Coach } from 'src/app/components/data/coach';
import { map } from 'rxjs/operators';
import { Level } from 'src/app/components/data/level';
import { ProgramModel } from 'src/app/components/data/program_model';

@Injectable({
  providedIn: 'root'
})
export class VodService {

  constructor(private db: AngularFireDatabase) { }

  getPrograms(subscriptions = null): Observable<any> {
    return this.db.list('/vod/program').valueChanges().pipe(
      map(programs => {
        if (subscriptions == null) {
          return programs;
        } else {
          return programs.filter((program: Program) => subscriptions.includes(program.id));
        }
      })
    )
  }

  getCoaches(): Observable<any> {
    return this.db.list('/vod/coach').valueChanges();
  }

  getCoachWithId(id): Observable<any> {
    return this.db.object('/vod/coach/' + id).valueChanges();
  }

  getCategories(): Observable<any> {
    return this.db.list('/category').snapshotChanges();
  }

  getCoachesWithProgramsId(programs: Program[]): Observable<Coach[]> {
    return new Observable<Coach[]>(observer => {
      let coaches = this.getCoaches();
      coaches.subscribe(coaches => {
        let coachesToReturn = [];
        programs.forEach(program => {
          let hasCoach = false;
          let alreadyAdded = false;
          coaches.forEach(coach => {
            if (program.coachId == coach.id) {
              hasCoach = true;
            }
            if (hasCoach && !alreadyAdded) {
              coachesToReturn.push(coach);
              alreadyAdded = true;
            }
          });
        });
        observer.next(coachesToReturn);
      });
    });
  };

  getLevels(): Observable<any> {
    return this.db.list('/vod/levels').valueChanges();
  }

  getCategoryList(): Observable<Category[]> {
    return new Observable<Category[]>(observer => {
      let items = this.db.list('/category').snapshotChanges().pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() as Category }))
        )
      );
      items.subscribe(categories => {
        let categoryFull: Category[] = [];
        categories.forEach(category => {
          let newCategory = {
            icon: category['icon'],
            name: category['name'],
            name_localized: category['name_localized'],
            code: category['code'],
            key: category['key']
          };
          categoryFull.push(newCategory);
        })
        observer.next(categoryFull);
      });
    });
  }

  getSingleProgramModel(programId: string): Observable<ProgramModel> {
    return combineLatest(this.getSingleProgramModelAsyncs())
      .pipe(map(([programs, coaches, levels, categories]) => {
        var programModel: ProgramModel = null;
        categories.forEach(category => {
          let filteredPrograms = programs.filter(p => p.categoryId == category.key);
          if (filteredPrograms.length > 0) {
            let programModels = filteredPrograms as ProgramModel[];
            programModels.forEach(model => {
              if (model.id == programId) {
                programModel = model;
                programModel.categoryName = category.name;

                //Coach
                coaches.forEach((coach: Coach) => {
                  if (model.coachId == coach.id) {
                    programModel.coachName = coach.name;
                  }
                });

                //Level
                let levelsInProgram = [];
                levels.forEach(level => {
                  if (model.levelIds.includes(level.id)) {
                    levelsInProgram.push(level);
                  }
                });
                let levelString = "";
                for (let index = 0; index < levelsInProgram.length; index++) {
                  const level: Level = levelsInProgram[index];
                  if (index > 0) {
                    levelString += ", ";
                  }
                  levelString += level.name;
                }
                programModel.levelName = levelString;
              }
            });
          }
        })
        return programModel;
      }))
  }

  getProgramModels(subscriptionIds = null): Observable<ProgramModel[]> {
    return combineLatest(this.getProgramModelAsyncs(subscriptionIds))
      .pipe(map(([programs, coaches, levels, categories]) => {
        const programsToReturn = [];
        categories.forEach(category => {
          let filteredPrograms = programs.filter(p => p.categoryId == category.key);
          if (filteredPrograms.length > 0) {
            programsToReturn.push(programsToReturn.length);
            let programModels = filteredPrograms as ProgramModel[];
            programModels.forEach(programModel => {
              programModel.categoryName = category.name;
              coaches.forEach((coach: Coach) => {
                if (programModel.coachId == coach.id) {
                  programModel.coachName = coach.name;
                }
              });

              let levelsInProgram = [];
              levels.forEach(level => {
                if (programModel.levelIds.includes(level.id)) {
                  levelsInProgram.push(level);
                }
              });
              let levelString = "";
              for (let index = 0; index < levelsInProgram.length; index++) {
                const level: Level = levelsInProgram[index];
                if (index > 0) {
                  levelString += ", ";
                }
                levelString += level.name;
              }
              programModel.levelName = levelString;
            });
            programsToReturn[programsToReturn.length - 1] = programModels;
          }
        });

        return programsToReturn;
      }));
  }

  private getProgramModelAsyncs(subscriptions = null): Observable<any>[] {
    return [this.getPrograms(subscriptions), this.getCoaches(), 
            this.getLevels(), this.getCategoryList()];
  }

  private getSingleProgramModelAsyncs(): Observable<any>[] {
    return [this.getPrograms(), this.getCoaches(), 
            this.getLevels(), this.getCategoryList()];
  }
}