import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reset-password-result',
  templateUrl: './reset-password-result.component.html',
  styleUrls: ['./reset-password-result.component.scss']
})
export class ResetPasswordResultComponent implements OnInit {
  @Input() isValid: Boolean;

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open(content) {
    this.modalService.open(content);
  }

  continue() {
    this.modalService.dismissAll();
  }
}
