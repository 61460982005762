import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { City } from 'src/app/components/data/city';
import { RegisterCity } from 'src/app/components/data/register/register_city';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private db: AngularFireDatabase) { }

  getCityList(): Observable<City[]> {
    return new Observable<City[]>(observer => {
      let citiesObservable  = this.db.list('/city').valueChanges().pipe(
        map((cities: City[]) => cities.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1))
      );
      citiesObservable.subscribe(cities => {
        observer.next(cities);
      })
    });
  }

  getCityWithName(name: string): Observable<RegisterCity> {
    return new Observable<RegisterCity>(observer => {
      this.db.list('/city').snapshotChanges().pipe(
        map(changes => 
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() as City }))
        )
      ).subscribe(cities => {
        //Default code set to Skopje
        const defaultKey = "-Lb5GrFwZMtM3EwFJmau";
        var foundCity = false;
        cities.forEach(city => {
          if (city.name.toLowerCase() == name.toLowerCase()) {
            observer.next({
              city: city,
              userCity: city.name
            });
            foundCity = true;
          }
        });
        if (!foundCity) {
          cities.forEach(city => {
            if (city.key == defaultKey) {
              const nameLowerCase = name.toLowerCase();
              const cityCapitalized = nameLowerCase.charAt(0).toUpperCase() + nameLowerCase.slice(1)
              observer.next({
                city: city,
                userCity: cityCapitalized
              });
            }
          })
        }
      });
    });
  }
}
