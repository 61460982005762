<div class="container">
    <div class="col-md-8">
        <p class="h1 font-weight-bold margin-top-96">Контактирај не:</p>
        <p class="lead">Мејл за контакт: 
            <a id="contactUs" class="lead link" href="mailto:info@fitkit.app">info@fitkit.app</a>
        </p>
        <form [formGroup]="contact" 
              class="form-horizontal margin-top-96 d-none"
              novalidate>
            <p class="h1 font-weight-bold">Контактирај не</p>
            <div class="form-group">
                <input type="text" 
                       class="form-control"
                       formControlName="subject"
                       placeholder="Внеси краток наслов">
            </div>
            <div class="form-group">
                <textarea class="form-control"
                          formControlName="message"
                          placeholder="Внеси мислење за подобрувања, критики, пофалби, итн..." 
                          rows="7"></textarea>
            </div>
            <div class="form-group">
                <button type="submit" 
                        class="custom-button custom-button-inverted" 
                        [disabled]="contact.invalid"
                        (click)="sendOpinion()">Испрати мејл</button>
            </div>
        </form>
    </div>
    <div class="margin-top-96"></div>
</div>