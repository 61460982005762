import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PageNavigation } from '../../data/page_navigation';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit {

  @Input() videoUrl : SafeResourceUrl;
  @Input() videoName : string;

  constructor(private modalService: NgbModal,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  open(content) {
      this.modalService.open(content, { windowClass : "video-modal" });
      this.navigationService.setCurrentPage(PageNavigation.VIDEO_POPUP);
  }

}
