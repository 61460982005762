<button id="resetPassModal" class="d-none" (click)="open(resetpassmodal)"></button>

<ng-template #resetpassmodal let-modal>
    <div class="popup-close-parent">
        <img id="popup-close" class="popup-close float-right" src="../../../assets/images/popup_close.png" (click)="modal.close()">
    </div>
    <div class="popup-body">
        <form [formGroup]="resetPassInfo" novalidate>
            <div class="input-group input-group-lg margin-popup-xsmall">
                <input #firstElement type="text" formControlName="email" placeholder="Имејл" class="form-control popup-input" aria-label="Large" aria-describedby="inputGroup-sizing-lg">
            </div>
            <div class="text-center margin-popup-medium">
                <button class="custom-button custom-button-primary border-0 shadow-nonе" [disabled]="resetPassInfo.invalid" (click)="resetPassword()">
                    Ресетирај лозинка
                </button>
            </div>
        </form>
    </div>
</ng-template>
<reset-password-result-popup [isValid]="isResetPassValid.getValue()"></reset-password-result-popup>