import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { PageNavigation } from '../data/page_navigation';
import { Profile } from '../data/profile/profile';
import { ProgramModel } from '../data/program_model';

@Component({
  selector: 'app-user-programs',
  templateUrl: './user-programs.component.html',
  styleUrls: ['./user-programs.component.scss']
})
export class UserProgramsComponent implements OnInit {

  public profile: Profile = this.authService.currentProfile;
  public isLoggedIn = this.profile != null;
  public isLoaded = false;
  public subscriptions: ProgramModel[];

  @ViewChildren('containerChild') mapElem: QueryList<ElementRef>;
  private isClick = false;
  
  constructor(private navigationService: NavigationService,
              public authService: AuthService,
              private subscriptionService: SubscriptionService,
              private router: Router) { }

  ngOnInit(): void {
    if (!this.isLoggedIn) {
      this.router.navigate(['/']);
      return;
    }
    this.navigationService.setCurrentPage(PageNavigation.MY_PROGRAMS);
    this.subscriptionService.getSubscribedPrograms(this.authService.currentProfile.id).subscribe(
      programModels => {
        this.subscriptions = programModels;
        this.isLoaded = true;
        setTimeout(() => {
          this.createClickListener();
        }, 200);
    });
  }

  explorePrograms() {
    this.router.navigate(['/']);
  }

  onProgramClick(program: ProgramModel) {
    if (this.isClick) {
      this.router.navigate(['details/', program.id]);
      console.log("on program click");
      this.isClick = false;
    }
  }

  private createClickListener() {
    this.mapElem.forEach(mapElem => {
      const mousedown$ = fromEvent(mapElem.nativeElement, 'mousedown');
      const mouseup$ = fromEvent(mapElem.nativeElement, 'mouseup');
  
      mousedown$.subscribe(() => {
        this.isClick = false;
        const clickTimer$ = timer(80);
        mouseup$.pipe(takeUntil(clickTimer$)).subscribe(() => {
          this.isClick = true;
          console.log("click");
        });
      });
    });
  }
}
