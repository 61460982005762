<button *ngIf="isPrimary && !isPopup" class="custom-button custom-button-primary border-0">
    {{buttonText}}
</button>
<button *ngIf="!isPrimary && !isInverted" class="custom-button custom-button-secondary text-white mt-3">
    {{buttonText}}
</button>
<button *ngIf="!isPrimary && isInverted" class="custom-button custom-button-secondary-inverted mt-3">
    {{buttonText}}
</button>
<button *ngIf="isPrimary && isPopup" class="custom-button-popup custom-button-primary border-0 shadow-none" [disabled]="isDisabled">
    {{buttonText}}
</button>