import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { VodProductModel } from 'src/app/components/data/vod.product.model';
import { ProgramModel } from 'src/app/components/data/program_model';
import { Subscription } from '../../components/data/subscription';
import { PaymentType } from '../../components/data/payment.type';
import { VodService } from '../vod/vod.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  public paymentTypeSubject = new BehaviorSubject<PaymentType>(PaymentType.Undefined);

  constructor(private db: AngularFireDatabase,
              private vodService: VodService) { }

  getSubscriptions(): Observable<Subscription[]> {
    return this.db.list('/vod/subscription').valueChanges() as Observable<Subscription[]>;
  }

  getSubscriptionForProgram(userId: string, programId: string): Observable<Subscription> {
    const today = new Date();
    return this.getSubscriptions().pipe(
      map(subscriptions => subscriptions.find(
        subscription => (subscription.userId == userId 
          && subscription.programId == programId))
      ));
  }

  getSubscribedPrograms(userId: string): Observable<ProgramModel[]> {
    const subscriptions$ = this.getSubscriptionsForUser(userId);
    const subscriptionProgramIds = [];

    return subscriptions$.pipe(
      map(subscriptions => {
        subscriptions.forEach(subscription => {
          subscriptionProgramIds.push(subscription.programId);
        });
      }),
      mergeMap(_ => this.vodService.getProgramModels(subscriptionProgramIds).pipe(
        map(programModels => {
          return programModels;
        })
    )));
  }

  getProductWithId(id: string): Observable<VodProductModel> {
    return this.db.object('/vod/product/' + id).valueChanges() as Observable<VodProductModel>;
  }

  setPaymentType(type: PaymentType) {
    this.paymentTypeSubject.next(type);
  }

  private getSubscriptionsForUser(userId: string): Observable<Subscription[]> {
    const today = new Date();
    return this.getSubscriptions().pipe(
      map(subscriptions => subscriptions.filter(
        subscription => (subscription.userId == userId))
      ));
  }
}